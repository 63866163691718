import {
  FETCH_ALL_EVENTS_START,
  FETCH_PUBLISHED_EVENTS_START,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_PUBLISHED_EVENTS_SUCCESS,
  FETCH_ALL_EVENTS_FAILED,
  FETCH_PUBLISHED_EVENTS_FAILED,
  UPDATE_EVENT,
  UPDATE_EVENT_DATA,
  FETCH_ITEM_DESCRIPTION,
  FETCH_ITEM_DESCRIPTION_START,
  FETCH_ITEM_DESCRIPTION_SUCCESS,
  FETCH_ITEM_DESCRIPTION_FAILED,
  ACTIVATE_CURRENT_EVENT_ITEM,
} from "../actions/actionTypes";
import { findByIdentifier, updateObjectByIdentifier } from "../../utils/object";
import Auth from "../../utils/auth";

import {
  getSortedEvents,
  getTopUpcomingEvents,
  getSortedActiveEvents,
  getUpdatedEventsOnItemActiveAndTime,
  getUpdatedEventsOnItemActive,
  getCurrentItemIndex,
  getUpdatedEvenstOnItemEnd,
} from "../../utils/eventsUtils";

const initialState = {
  events: [],
  topEvents: [],
  currentItemDescription: { description: null, shortDescription: null },
};
const auth = new Auth();
const eventsReducer = (state = initialState, action) => {
  auth.checkActionForAuthFailure(action);
  switch (action.type) {
    case FETCH_ALL_EVENTS_START:
    case FETCH_PUBLISHED_EVENTS_START:
      return {
        ...state,
      };
    case FETCH_ALL_EVENTS_SUCCESS:
    case FETCH_PUBLISHED_EVENTS_SUCCESS:
      let events = state.events;
      console.log("action", action);
      const eventsResponse = action.result.data ?? [];
      if (action.eventId) {
        // Update only single event
        if (eventsResponse.length === 1) {
          if (findByIdentifier(state.events, eventsResponse[0], "id")) {
            events = updateObjectByIdentifier(events, eventsResponse[0], "id");
          } else {
            events.push(eventsResponse[0]);
          }
        }
      } else {
        events = eventsResponse;
      }
      return {
        ...state,
        events: getSortedEvents(events),
        topEvents: getTopUpcomingEvents(events),
      };
    case FETCH_ALL_EVENTS_FAILED:
    case FETCH_PUBLISHED_EVENTS_FAILED:
      return {
        ...state,
      };
    case UPDATE_EVENT:
      return {
        ...state,
        events: updateObjectByIdentifier(state.events, action.event, "id"),
        topEvents: updateObjectByIdentifier(
          state.topEvents,
          action.event,
          "id"
        ),
      };
    case UPDATE_EVENT_DATA:
      return {
        ...state,
        events: [...action.events],
      };
    case FETCH_ITEM_DESCRIPTION:
    case FETCH_ITEM_DESCRIPTION_START:
      return {
        ...state,
      };
    case FETCH_ITEM_DESCRIPTION_SUCCESS:
      const eventId = action.eventId;
      const itemId = action.itemId;
      const response = action.result.response ?? {};
      let eventsArray = state.events;

      let currentEvent = eventsArray.filter((item) => +item.id === +eventId)[0];
      const currentEventIdx = eventsArray.findIndex(
        (item) => +item.id === +eventId
      );
      const currentItemObject = currentEvent.items.filter(
        (item) => item.id === itemId
      )[0];
      const currentItemIdx = currentEvent.items.findIndex(
        (item) => item.id === itemId
      );

      currentItemObject.additionalItemDescription = response.shortDescription;
      currentItemObject.itemDetail.description = response.description;
      console.log(response);
      currentEvent = currentEvent.items.splice(
        currentItemIdx,
        1,
        currentItemObject
      );
      eventsArray = eventsArray.splice(currentEventIdx, 1, currentEvent);
      return {
        ...state,
        events: eventsArray,
      };
    case FETCH_ITEM_DESCRIPTION_FAILED:
      return {
        ...state,
      };
    case ACTIVATE_CURRENT_EVENT_ITEM:
      const activeCurrentEvent = state.events.filter(
        (event) => event.id === action.eventId
      );

      const activeItemIndex = getCurrentItemIndex(
        activeCurrentEvent[0],
        action.itemId
      );

      if (activeItemIndex >= 0) {
        const updatedEvents = getUpdatedEventsOnItemActive(
          state.events,
          action.eventId,
          action.itemId,
          activeCurrentEvent[0],
          activeItemIndex
        );
        return {
          ...state,
          events: updatedEvents,
        };
      }

      return {
        ...state,
      };
    default:
      return state;
  }
};

export default eventsReducer;
