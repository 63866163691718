import React from 'react';
import Logo from '../Logo/Logo';
import {useTranslation} from 'react-i18next';

import menuIcon from '../../../../assets/svg/mobile-menu.svg';
import invertedMenuIcon from '../../../../assets/svg/inverted-mobile-menu.svg';
import heartIcon from '../../../../assets/svg/mobile-heart.svg';
import invertedHeartIcon from '../../../../assets/svg/inverted-mobile-heart.svg';
import { useHistory } from 'react-router-dom';
import './MobileHeader.scss';
import {useDispatch, useSelector} from 'react-redux';
import {sidebarShow} from '../../../../store/actions/app';
import AccountSidebar from '../../Sidebar/AccountSidebar/AccountSidebar';
import {HEADER_INVERTED} from '../../../../constants';

const MobileHeader = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user} = useSelector((state) => state.user);
    const {inverted} = useSelector((state) => state.app.header);
    const onMenuClick = () => {
        dispatch(sidebarShow(<AccountSidebar shownMenu={true}/>));
    };

    const onWishlistClick = () => {
        if(user){
            history.push(`/${i18n.language}/wishlist`);
        }else{
            dispatch(sidebarShow(<AccountSidebar shownMenu={false}/>));
        }
    };

    return (
        <header className={['mobile', inverted === HEADER_INVERTED ? 'inverted' : null].join(' ')}>
            <div className="menu-handler">
                <span className="menu-icon" onClick={onMenuClick}>
                    <img src={inverted === HEADER_INVERTED ? invertedMenuIcon : menuIcon} alt={t('menu')} />
                </span>
            </div>
            <Logo/>
            <div className="wishlist-handler">
                <span className="wishlist-icon" onClick={onWishlistClick}>
                    <img src={inverted === HEADER_INVERTED ? invertedHeartIcon : heartIcon} alt={t('wishlist')}/>
                </span>
            </div>
        </header>
    );
};

export default MobileHeader;
