import {
  WINDOW_RESIZE,
  SIDEBAR_SHOW,
  SIDEBAR_HIDE,
  NOTIFICATION_BAR_SHOW,
  NOTIFICATION_BAR_HIDE,
  WINDOW_SCROLL,
  CHANGE_HEADER,
} from "../actions/actionTypes";
import { HEADER_NORMAL } from "../../constants";

const initialState = {
  device: {
    isSmMobile: window.innerWidth < 576,
    isMobile: window.innerWidth < 768 || window.innerHeight < 480,
    isTablet: window.innerWidth > 767 && window.innerWidth < 1024,
    isDesktop: window.innerWidth > 1023,
    isMidDesktop: window.innerWidth > 1199,
    isLargeDesktop: window.innerWidth > 1440,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    scrollY: window.scrollY,
  },
  sidebar: null,
  notificationBar: null,
  header: {
    inverted: HEADER_NORMAL,
  },
  regions: {
    europe: [
      "Austria",
      "Belgium",
      "France",
      "Germany",
      "Liechtenstein",
      "Luxembourg",
      "Monaco",
      "Netherlands",
      "Switzerland",
      "Belarus",
      "Bulgaria",
      "Czechia",
      "Hungary",
      "Poland",
      "Republic of Moldova",
      "Romania",
      "Russian Federation",
      "Slovakia",
      "Ukraine",
      "Denmark",
      "Estonia",
      "Finland",
      "Iceland",
      "Ireland",
      "Latvia",
      "Lithuania",
      "Norway",
      "Sweden",
      "United Kingdom",
      "Albania",
      "Andorra",
      "Bosnia and Herzegovina",
      "Croatia",
      "Greece",
      "Italy",
      "Malta",
      "Montenegro",
      "North Macedonia",
      "Portugal",
      "San Marino",
      "Serbia",
      "Slovenia",
      "Spain",
      "Vatican City",
      "Russia",
      "Turkey",
      "Kazakhstan",
      "Azerbaijan",
      "Georgia",
      "Armenia",
      "Cyprus",
    ],
    "european union": [
      "Austria",
      "Belgium",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Ireland",
      "Italy",
      "Latvia",
      "Lithuania",
      "Luxembourg",
      "Malta",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
    ],
    asia: [
      "China",
      "India",
      "Indonesia",
      "Pakistan",
      "Bangladesh",
      "Russia",
      "Japan",
      "Philippines",
      "Vietnam",
      "Iran",
      "Turkey",
      "Thailand",
      "Myanmar",
      "South Korea",
      "Iraq",
      "Afghanistan",
      "Uzbekistan",
      "Saudi Arabia",
      "Malaysia",
      "Yemen",
      "Nepal",
      "North Korea",
      "Taiwan",
      "Sri Lanka",
      "Syria",
      "Kazakhstan",
      "Cambodia",
      "Jordan",
      "Azerbaijan",
      "United Arab Emirates",
      "Tajikistan",
      "Israel",
      "Hong Kong",
      "Lebanon",
      "Kyrgyzstan",
      "Laos",
      "Singapore",
      "Turkmenistan",
      "Palestine",
      "Oman",
      "Kuwait",
      "Georgia",
      "Mongolia",
      "Armenia",
      "Qatar",
      "Timor-Leste (East Timor)",
      "Bahrain",
      "Cyprus",
      "Bhutan",
      "Macau",
      "Maldives",
      "Brunei",
    ],
  },
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case WINDOW_RESIZE:
      return {
        ...state,
        device: {
          isSmMobile: window.innerWidth < 576,
          isMobile: window.innerWidth < 768 || window.innerHeight < 480,
          isTablet: window.innerWidth > 767 && window.innerWidth < 1024,
          isDesktop: window.innerWidth > 1023,
          isMidDesktop: window.innerWidth > 1199,
          isLargeDesktop: window.innerWidth > 1440,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          scrollY: window.scrollY,
        },
      };
    case WINDOW_SCROLL:
      return {
        ...state,
        device: {
          ...state.device,
          scrollY: window.scrollY,
        },
      };
    case SIDEBAR_SHOW:
      return {
        ...state,
        sidebar: action.data,
      };
    case SIDEBAR_HIDE:
      return {
        ...state,
        sidebar: null,
      };
    case NOTIFICATION_BAR_SHOW:
      return {
        ...state,
        notificationBar: action.data,
      };
    case NOTIFICATION_BAR_HIDE:
      return {
        ...state,
        notificationBar: null,
      };
    case CHANGE_HEADER:
      return {
        ...state,
        header: {
          ...state.header,
          inverted: action.invert,
        },
      };
    default:
      return state;
  }
};

export default appReducer;
