import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';

const LoadableNotFound = loadable(() => import("../../components/NotFound/NotFound.js"), {
    fallback: <Loader/>
});

const NotFound = () => {
    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('metaTitlePage', {page: t('notFound')})}</title>
            </Helmet>
            <LoadableNotFound/>
        </>
    );
}


export default NotFound;
