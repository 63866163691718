import {LOCALES, LOCALE} from '../config/app';
import {parse} from 'date-fns';

export const parseDate = (date) => {
    // June 21, 2021 05:24:00+0000
    return parse(date, 'MMMM dd, yyyy kk:mm:ssxxxx', new Date());
};

export const languageToLocale = (language) => {
    return LOCALES[language] ?? LOCALE;
};

export const isDate = (date) => {
    let newDate;
    if (date instanceof Date) {
        newDate = date;
    } else {
        newDate = parseDate(date);
    }
    return newDate;
};

export const getDayMonth = (date, language) => {
    return `${isDate(date).toLocaleDateString(languageToLocale(language), {day: '2-digit'})}.${isDate(date).toLocaleDateString(languageToLocale(language), {month: '2-digit'})}.`;
};

export const getDayMonthYear = (date, language) => {
    return isDate(date).toLocaleDateString(languageToLocale(language), {month: '2-digit', day: '2-digit', year: 'numeric'});
};

export const getTimeInMillisecondsFromDate = (date) => isDate(date).getTime();

export const getTimeFromDate = (date, language) => {
    const time = isDate(date).toLocaleTimeString(languageToLocale(language), {hour: '2-digit', minute: '2-digit'});
    if (language === 'fr') {
        return time.replace(':', 'h');
    }
    return time;
};
export const getHoursFromDate = (date, language) => {
    return isDate(date).toLocaleTimeString(languageToLocale(language), {hour: '2-digit'});
};

export const getMinutesFromDate = (date, language) => {
    return isDate(date).toLocaleTimeString(languageToLocale(language), {minute: '2-digit'});
};

export const getSecondsFromDate = (date, language) => {
    return isDate(date).toLocaleTimeString(languageToLocale(language), {second: '2-digit'});
};

export const addLeadingZeros = (num, padLen, padChar) =>{
    let padCharChecked = typeof padChar !== 'undefined' ? padChar : '0';
    const pad = new Array(1 + padLen).join(padCharChecked);
    return (pad + num).slice(-pad.length);
};
