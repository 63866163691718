import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';

const LoadableCalendar = loadable(() => import("../../components/EventCalendar/EventCalendar.js"), {
    fallback: <Loader/>
});

const EventCalendar = () => <LoadableCalendar/>;

export default EventCalendar;
