import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import SwaggerMiddleware from './middleware/swagger';
import AxiosMiddleware from './middleware/axiosMiddleware';
import appReducer from './reducers/app';
import eventsReducer from './reducers/events';
import userReducer from './reducers/user';
import bidReducer from './reducers/bid';
import buyReducer from './reducers/buy';
import wishlistReducer from './reducers/wishlist';
import transactionStatusReducer from './reducers/transactionStatus';

import { SWAGGER_ENDPOINT, API_BASE_URL } from '../config/app';

const getStore = (reducers) => {
    const rootReducer = combineReducers(reducers);
    const logger = (store) => (next) => (action) => {
        if (
            process.env.REACT_APP_ENV === 'development' ||
            process.env.REACT_APP_ENV === 'staging'
        ) {
            // console.log('[Middleware] Dispatching', action);
        }
        const result = next(action);
        if (
            process.env.REACT_APP_ENV === 'development' ||
            process.env.REACT_APP_ENV === 'staging'
        ) {
            // console.log('[Middleware] next state', store.getState());
        }
        return result;
    };

    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(
                logger,
                thunk,
                AxiosMiddleware({
                    baseURL: API_BASE_URL,
                }),
                // SwaggerMiddleware({
                //     url: SWAGGER_ENDPOINT,
                // })
            )
        )
    );
};

// Add reducers here!
const store = getStore({
    app: appReducer,
    events: eventsReducer,
    user: userReducer,
    bid: bidReducer,
    buy: buyReducer,
    wishlist: wishlistReducer,
    transactionStatus: transactionStatusReducer,
});

export default store;
