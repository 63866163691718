import {
    SIDEBAR_SHOW,
    SIDEBAR_HIDE,
    WINDOW_RESIZE,
    WINDOW_SCROLL,
    NOTIFICATION_BAR_SHOW,
    NOTIFICATION_BAR_HIDE,
    CHANGE_HEADER
} from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const windowResize = () => ({
    type: WINDOW_RESIZE
});

export const windowScroll = () => ({
    type: WINDOW_SCROLL
});

export const sidebarShow = (content) => ({
   type: SIDEBAR_SHOW,
   data: content
});

export const sidebarHide = () => ({
    type: SIDEBAR_HIDE
});

export const notificationBarShow = (content) => ({
    type: NOTIFICATION_BAR_SHOW,
    data: content
});

export const notificationBarHide = () => ({
    type: NOTIFICATION_BAR_HIDE
});

export const invertHeader = (invert) => ({
    type: CHANGE_HEADER,
    invert: invert
});

