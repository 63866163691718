import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';

const LoadableLoginRedirectHandler = loadable(() => import("../../components/LoginRedirectHandler/LoginRedirectHandler.js"), {
    fallback: <Loader/>
});

const LoginRedirectHandler = () => <LoadableLoginRedirectHandler/>;

export default LoginRedirectHandler;
