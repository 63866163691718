import React, {useState, useEffect, useCallback} from 'react';
import { useHistory } from "react-router-dom";
import DropdownArrow from '../../../assets/svg/dropdown-arrow-white.svg';
import { useTranslation } from 'react-i18next';
import GerFlag from '../../../assets/svg/ger-flag.svg';
import FrFlag from '../../../assets/svg/fr-flag.svg';
// import EspFlag from '../../../assets/svg/esp-flag.svg';
import UkFlag from '../../../assets/svg/uk-flag.svg';

import './LanguageSwitch.scss';

const LanguageSwitch = () => {
    const { i18n } = useTranslation();
    const history = useHistory();
    const [showLanguages, setShowLanguages] = useState(false);
    const options = [
        {id: 1, key: 'de', name: 'Deutsch', img:GerFlag},
        {id: 2, key: 'en', name: 'English', img:UkFlag},
        /* {id: 3, key: 'es', name: 'Espanol', img:EspFlag}, */
        {id: 4, key: 'fr', name: 'Français', img:FrFlag}
    ];
    const {language} = i18n;
    const currentLanguage = options.find((option) => option.key === language);
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage ? currentLanguage : options[0]);
    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang.key);
        setSelectedLanguage(lang);
    };
    const handleSelectingLanguage = (lng) => {
        changeLanguageHandler(lng);
        setShowLanguages(false);
    };
    const outsideClick = useCallback(() => {
        if (showLanguages) {
            setShowLanguages(false);
        }
    }, [showLanguages]);

    useEffect(() => {
        window.addEventListener('click', outsideClick);
        return () => window.removeEventListener('click', outsideClick);
    });

    const languagesListElem = options.map((lng) => {
        if (lng.id !== selectedLanguage.id) {
            return (
                <div
                    key={lng.id}
                    className="language"
                    onClick={() => handleSelectingLanguage(lng)}
                >
                    <div className="language-name">
                        {lng.name}
                    </div>
                    <div className="language-flag">
                        <img src={lng.img} alt={`${lng.name} flag`}/>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    });

    return (
        <div className={`language-switch${showLanguages ? ' opened' : ''}`}>
            <div className="language selected" onClick={() => setShowLanguages(!showLanguages)}>
                <div className="language-name">
                    {selectedLanguage.name}
                </div>
                <div className="language-flag">
                    <img src={selectedLanguage.img} alt={`${selectedLanguage.name} flag`}/>
                </div>
                {
                    !showLanguages ? (
                        <div className="dropdown-arrow">
                            <img src={DropdownArrow} alt="dropdown arrow"/>
                        </div>
                    ) : null
                }
            </div>
            {showLanguages ? languagesListElem : null}
        </div>
    );
};

export default LanguageSwitch;
