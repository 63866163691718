import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Routing from './Routing';
import {LNG} from '../config/app';
import LoadableLoginRedirectHandler from './loginRedirectHandler';

const LanguageRouting = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={`/login-redirect-handler`} component={LoadableLoginRedirectHandler}/>
                <Route path={`/:lng`} component={Routing}/>)
                <Redirect to={`/${LNG}`}/>
            </Switch>
        </BrowserRouter>
    );
};

export default LanguageRouting;
