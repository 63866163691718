const API_URLS = {
    // event controller
    getAllPublishedEvents: '/events/published/all',
    getAllEvents: '/events/all',
    getEventItems: '',

    // bid controller
    getLastBid: '/bids/current',
    placeBid: '/bids/placeBid',
    addToCart: '/bids/buyItNow',

    // buyer controller
    getTransactionStatus: '/buyers/getTransactionStatus',
    getMyAuctions: '/buyers/myAuctions',
    getWishlist: '/buyers/wishlist',
    addEventToWishlist: '/buyers/wishlist/event/add',
    addItemToWishlist: '/buyers/wishlist/item/add',

    // item controller
    getDescriptionAndShortDescription: '/items/getDescription',

    // user controller
    getSessionId: '/login/sessionId',
    fetchToken: '/login/ebayToken',
    logout: '/logout/',

    // newsletter controller
    getNewsletter: '/newsletter',
    saveEmail: '/newsletter/email',
};

export default API_URLS;
