import React from 'react';

import './Loader.scss';
import rings from '../../../assets/svg/rings.svg';

const Loader = ({relative}) => {
    return (
        <div className={['loader', relative ? 'relative' : ''].join(' ')}>
            <img src={rings} alt="loading"/>
        </div>
    );
};

export default Loader;
