import {
    FETCH_WISHLIST,
    FETCH_WISHLIST_START,
    FETCH_WISHLIST_SUCCESS,
    FETCH_WISHLIST_FAILED,
    WISHLIST_ITEM_ACTIONS_SUCCESS,
    WISHLIST_EVENT_ACTIONS_SUCCESS
} from '../actions/actionTypes';
import Auth from '../../utils/auth';
let localWishlist = JSON.parse(localStorage.getItem("wishlist"));

const initialState = {
    wishlist: localWishlist && localWishlist ? localWishlist: [],
};
const auth = new Auth();
const wishlistReducer = (state = initialState, action) => {
    auth.checkActionForAuthFailure(action);
    switch (action.type) {
        case FETCH_WISHLIST:
        case FETCH_WISHLIST_START:
            return {
                ...state,
            };
        case WISHLIST_ITEM_ACTIONS_SUCCESS:
            const wishlistAdd = action.result ?? [];
            localStorage.setItem("wishlist",JSON.stringify(wishlistAdd))
            return {
                ...state,
                wishlist: wishlistAdd
            };
        case WISHLIST_EVENT_ACTIONS_SUCCESS:
            const wishlistEventsAdd = action.result ?? [];
            localStorage.setItem("wishlist",JSON.stringify(wishlistEventsAdd))
            return {
                ...state,
                wishlist: wishlistEventsAdd
            };
        case FETCH_WISHLIST_SUCCESS:
            const wishlist = action.result ?? [];
            localStorage.setItem("wishlist",JSON.stringify(wishlist))
            localStorage.setItem("wishlistLng",action.lng)
            return {
                ...state,
                wishlist: wishlist
            };
        case FETCH_WISHLIST_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default wishlistReducer;
