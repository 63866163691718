import React from 'react';
import {useSelector} from 'react-redux';

import './NotificationBar.scss';
import PayBar from './PayBar/PayBar';

const NotificationBar = () => {
    let {notificationBar} = useSelector((state) => state.app);
    const {events} = useSelector((state) => state.events);
    const {user} = useSelector((state) => state.user);
    if (user && !notificationBar && events) {
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            if (event.items) {
                for(let x = 0; x < event.items.length; x++) {
                    const item = event.items[x];
                    if (item.sold && item.winning) {
                        notificationBar = <PayBar/>;
                        break;
                    }
                }
            }
            if (notificationBar) break;
        }
    }
    if (!notificationBar) return null;
    return (
        <div className="notification-bar-wrapper">
            {notificationBar}
        </div>
    );

};

export default NotificationBar;
