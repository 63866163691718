import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import {REACT_APP_ENV_BUILD_TIME} from './config/app';

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: ['de', 'en', 'fr', /* 'es' */],
        supportedLngs: ['de', 'en', 'fr', /* 'es' */],
        preload: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            queryStringParams: {v: REACT_APP_ENV_BUILD_TIME}
        }
    });

export default i18n;
