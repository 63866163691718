import {
    USER_FETCH_LOGIN_URL,
    USER_FETCH_LOGIN_URL_SUCCESS,
    USER_FETCH_LOGIN_URL_FAILURE,
    USER_LOG_OUT,
    USER_FETCH_TOKEN,
    USER_FETCH_TOKEN_SUCCESS,
    USER_FETCH_TOKEN_FAILURE,
    USER_LOG_OUT_SUCCESS,
    USER_LOG_OUT_FAILURE,
    USER_SUBSCRIBE_NEWSLETTER,
    USER_SUBSCRIBE_NEWSLETTER_SUCCESS,
    USER_SUBSCRIBE_NEWSLETTER_FAILURE,
    USER_DECLINE_NEWSLETTER,
    USER_DECLINE_NEWSLETTER_SUCCESS,
    USER_DECLINE_NEWSLETTER_FAILURE,
    USER_FETCH_AUCTIONS,
    RESET_USER_ERROR_POPUP,
    USER_FETCH_AUCTIONS_SUCCESS, USER_FETCH_AUCTIONS_FAILURE
} from '../actions/actionTypes';
import Auth from "../../utils/auth";

const auth = new Auth();
const initialState = {
    user: auth.getUserCookie(),
    loading: false,
    fetchTokenError: false,
    newsletter: null,
    newsletterMail: null,
    userError:false,
    userAuthFailed:false,
    auctions: {
        PARTICIPATED : [],
        COMPLETED: [],
        WON: []
    }
};
const userReducer = (state = initialState, action) => {
    auth.checkActionForAuthFailure(action);
    let user;
    let auctions;
    switch (action.type) {
        case USER_FETCH_LOGIN_URL:
        case USER_FETCH_TOKEN:
        case USER_LOG_OUT:
        case USER_FETCH_AUCTIONS:
            return {
                ...state,
                loading: true
            };
        case RESET_USER_ERROR_POPUP :
            return {
                ...state,
                userError: false,
                userAuthFailed:false
            };
        case USER_FETCH_LOGIN_URL_FAILURE:
            return {
                ...state,
                user: null,
                loading: false,
                fetchTokenError: false
            };
        case USER_FETCH_TOKEN_FAILURE:
            auth.removeAuth();
            return {
                ...state,
                user: null,
                loading: false,
                fetchTokenError: false
            };
        case USER_FETCH_LOGIN_URL_SUCCESS:
            user = action.result ?? {};
            if (user.url ?? false) {
                window.location.href = user.url;
            }
            return {
                ...state,
                loading: false
            };
        case USER_FETCH_TOKEN_SUCCESS:
            console.log("user fetch token", action)
            user = action.result ?? {};
            if(user.error === "Need additional details for the account to be able to buy items"){
                auth.removeAuth();
                return {
                    ...state,
                    user: null,
                    loading: false,
                    fetchTokenError: true,
                    userError:true
                };
            }
            if(user.error === "Authentication failed"){
                auth.removeAuth();
                return {
                    ...state,
                    user: null,
                    loading: false,
                    fetchTokenError: true,
                    userAuthFailed:true
                };
            }
            if (user.error) {
                auth.removeAuth();
                return {
                    ...state,
                    user: null,
                    loading: false,
                    fetchTokenError: true
                };
            }
            auth.setUser(user);
            return {
                ...state,
                user: user,
                loading: false
            };
        case USER_LOG_OUT_FAILURE:
        case USER_LOG_OUT_SUCCESS:
            localStorage.clear()
            auth.removeAuth();
            return {
                ...state,
                user: null,
                loading: false
            };
        case USER_DECLINE_NEWSLETTER:
            user = {...state.user};
            user.showSubscriptionPopup = false;
            auth.setUser(user);
            return {
                ...state,
                user: user,
                newsletter: USER_DECLINE_NEWSLETTER,
                newsletterMail: null
            }
        case USER_SUBSCRIBE_NEWSLETTER:
            return {
                ...state,
                newsletter: USER_SUBSCRIBE_NEWSLETTER,
                newsletterMail: action.email
            }
        case USER_DECLINE_NEWSLETTER_SUCCESS:
            return {
                ...state,
                newsletter: USER_DECLINE_NEWSLETTER_SUCCESS
            }
        case USER_SUBSCRIBE_NEWSLETTER_SUCCESS:
            return {
                ...state,
                newsletter: USER_SUBSCRIBE_NEWSLETTER_SUCCESS
            }
        case USER_DECLINE_NEWSLETTER_FAILURE:
            return {
                ...state,
                newsletter: USER_DECLINE_NEWSLETTER_FAILURE
            };
        case USER_SUBSCRIBE_NEWSLETTER_FAILURE:
            return {
                ...state,
                newsletter: USER_SUBSCRIBE_NEWSLETTER_FAILURE
            };
        case USER_FETCH_AUCTIONS_SUCCESS:
            auctions = {
                ...state.auctions
            };
            auctions[action.auctionType] = action.result ?? [];
            return {
                ...state,
                auctions: auctions
            }
        case USER_FETCH_AUCTIONS_FAILURE:
            auctions = {
                ...state.auctions
            };
            auctions[action.auctionType] = [];
            return {
                ...state,
                auctions: auctions
            }
        default:
            return state;
    }
};

export default userReducer;
