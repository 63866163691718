import loadable from '@loadable/component';
import Loader from '../../components/SharedComponents/Loader/Loader';

const LoadableVIP = loadable(() => import('../../components/VIP/VIP.js'), {
    fallback: <Loader/>
});

const VIP = () => <LoadableVIP/>;

export default VIP;
