import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {resetUserErrorPopup} from "../../../store/actions/user";
import './ErrorPopup.scss';

const ErrorPopup = (
) => {
    const {t} = useTranslation('common');
    const {isTablet, isMobile} = useSelector((state) => state.app.device);
    const dispatch = useDispatch();
    const popUpText = (className, text) => {
        return (
            <div className={`${className}-confirm`}>
                {text}
            </div>
        )
    };

    const hidePopup = ()=>{
        dispatch(resetUserErrorPopup());
    }

    return (
        <div className="cover">
            <div
                className={`ending-bid-pop-up-confirm ${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''}`}
            >
                <div className="bid-ending">
                    <div className="message-and-price">
                        {popUpText('title', t('userErrorTextTitle'))}
                        {popUpText('message', t('userErrorTextLine1'))}
                        <div className={'message-confirm'} dangerouslySetInnerHTML={
                            {__html: t('userErrorTextLine2', {interpolation: {escapeValue: false}})}
                        } />
                    </div>
                    <span
                        className="abort-confirm"
                        onClick={() => hidePopup()}
                    >
                        <u>
                            {t('cancel')}
                        </u>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ErrorPopup;
