import Cookies from "universal-cookie/lib";
import {OAUTH_ENDPOINT, OAUTH_REFRESH_ENDPOINT} from "../config/app";

class Auth {
    constructor() {
        this.cookies = new Cookies();
    }

    async checkAuthorization() {
        const auth = this.getAuthCookie();
        if (auth && auth.expiresIn > new Date().getTime()) {
            return this.extractAuthToken(auth);
        } else if (
            auth && auth.expiresIn < new Date().getTime()
            && auth.refreshToken
            && auth.refreshTokenExpiresIn > new Date().getTime()
        ) {
            const newToken = await this.fetchRefreshToken(auth);
            if (newToken.error) {
                this.removeAuth();
                return this.extractAuthToken(await this.fetchAuthToken());
            }
            return this.extractAuthToken(auth);
        }
        return this.extractAuthToken(await this.fetchAuthToken());
    }

    async fetchAuthToken() {
        const self = this;
        return await fetch(OAUTH_ENDPOINT, {
            method: "post"
        }).then(res => res.json())
            .then(res => {
                const auth = {
                    ...res,
                    "expiresIn": self.calcExpire(res.expiresIn)
                };
                self.setAuthCookie(auth);
                return auth;
            });
    }

    extractAuthToken(auth) {
        if (auth.tokenType && auth.access_token) {
            return this.buildAuthString(auth.tokenType, auth.access_token);
        }
        this.removeAuth();
        return false;
    }

    buildAuthString(tokenType, token) {
        return `${tokenType}${token}`;
    }

    async fetchRefreshToken(auth) {
        if (!auth.refreshToken) return false;
        const self = this;
        return await fetch(`${OAUTH_REFRESH_ENDPOINT}?refreshToken=${auth.refreshToken}`, {
            method: "get"
        }).then(res => res.json())
            .then(res => {
                if (res.response) {
                    self.setAuthCookie(self.userTokenToAuth(res.response, auth));
                }
                if (res.error) {
                    return res;
                }
                return auth;
            });
    }

    checkActionForAuthFailure(action) {
        if (
            action.result?.body?.responseMessage &&
            action.result.body.responseMessage === "Session token is invalid or expired"
        ) {
            console.error("RELOAD");
            this.removeAuth();
            window.location.reload();
        }
    }

    removeAuth() {
        this.cookies.remove('auth', {path: '/'});
        this.cookies.remove('user', {path: '/'});
        return false;
    }

    setUser(user) {
        if (!user.userToken || user.error) return this.removeAuth();
        const {userToken} = user;
        const auth = this.userTokenToAuth(userToken, this.getAuthCookie());

        this.setAuthCookie(auth);
        this.setUserCookie(user);
    }

    userTokenToAuth(userToken, auth) {
        auth.access_token = userToken.sessionToken;
        auth.expiresIn = this.calcExpire(userToken.sessionTokenExpiry);
        auth.refreshToken = userToken.refreshToken;
        auth.refreshTokenExpiresIn = this.calcExpire(userToken.refreshTokenExpiry);
        return auth;
    }

    calcExpire(expire) {
        return (new Date().getTime() + ((expire) * 1000));
    }

    getUserCookie() {
        return this.cookies.get('user') ?? null;
    }

    setUserCookie(user) {
        return this.cookies.set('user', user, {path: '/'});
    }

    getAuthCookie() {
        return this.cookies.get('auth') ?? null;
    }

    setAuthCookie(auth) {
        return this.cookies.set('auth', auth, {path: '/'});
    }
}

export default Auth;
