import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ENDED, IN_PROGRESS } from "../constants";
import { LNG } from "../config/app";
import Home from "./home";
import NotFound from "./notFound";
import LoadableUpcomingEvents from "./upcomingEvents";
import LoadableVIP from "./VIP";
import LoadableUpcomingEventsView from "./upcomingEvents/view";
import LoadableAboutUs from "./aboutUs";
import LoadableFAQ from "./FAQ";
import LoadableEventCalendar from "./eventCalendar";
import LoadableWishlist from "./wishlist";
import LoadableMyAuctions from "./myAuctions";
import LoadableLiveBuy from "./liveBuy";
import LoadableLisaTest from "./lisaTest";
import Header from "../components/SharedComponents/Header/Header";
import Footer from "../components/SharedComponents/Footer/Footer";
import Sidebar from "../components/SharedComponents/Sidebar/Sidebar";
import NotificationBar from "../components/SharedComponents/NotificationBar/NotificationBar";
import ScrollToTop from "../components/SharedComponents/ScrollToTop/ScrollToTop";
import StompWrapper from "../components/SharedComponents/StompWrapper/StompWrapper";
import {
  fetchPublishedEvents,
  updateEvent,
  updateCurrentEventItem,
  activateCurrentEventItem,
} from "../store/actions/events";
import {
  getSortedActiveEvents,
  getUpdatedEventsOnItemActiveAndTime,
} from "../utils/eventsUtils";
import ErrorPopup from "../components/LoginRedirectHandler/ErrorPopup/ErrorPopup";
import ErrorPopupAuthFailed from "../components/LoginRedirectHandler/ErrorPopupAuthFailed/ErrorPopupAuthFailed";
import { updateLastBid, activationTime, addBid } from "../store/actions/bid";

//Add new routes below
const Routing = () => {
  const { i18n } = useTranslation();
  const { lng } = useParams();
  let urlParts = window.location.pathname.split("/");
  const { userError, userAuthFailed } = useSelector((state) => state.user);
  const { isMobile } = useSelector((state) => state.app.device);
  const { events } = useSelector((state) => state.events);
  const currentEvent = [...getSortedActiveEvents(events)].shift();
  let changing = false;

  const [localEvents, setLocalEvents] = useState([...events]);

  if (lng !== i18n.language || !i18n.languages.includes(urlParts[1])) {
    i18n.changeLanguage(lng);
  }

  i18n.on("languageChanged", (newLng) => {
    let urlParts = window.location.pathname.split("/");
    if (!changing) {
      changing = true;
      // if (newLng !== lng && i18n.languages.includes(urlParts[1])) {
      //   urlParts[1] = newLng;
      // } else if (!i18n.languages.includes(urlParts[1])) {
      //   urlParts.shift();
      //   urlParts.unshift("", LNG);
      // }
      // // There must be a better way. This throws errors....
      // window.location.pathname = urlParts.join("/");
      window.location.pathname = `/${newLng}`;
    }
  });

  const dispatch = useDispatch();
  const onMessage = (parsedMessage) => {
    console.log("websocket msg:", parsedMessage);
    if (parsedMessage.eventUpdated) {
      dispatch(fetchPublishedEvents(i18n.language));
    }

    if (parsedMessage.eventStarted) {
      dispatch(
        updateEvent({
          id: parsedMessage.eventId,
          status: IN_PROGRESS,
          eventStartedLocally: true,
        })
      );
    }

    if (parsedMessage.eventEnded) {
      localStorage.removeItem("wishlist")
      dispatch(
        updateEvent({
          id: parsedMessage.eventId,
          status: ENDED,
        })
      );
    }

    if (parsedMessage.active) {
      dispatch(
        activateCurrentEventItem(parsedMessage.itemId, parsedMessage.eventId)
      );
      dispatch(activationTime(parsedMessage.activationTime));
    }

    if (parsedMessage.bidAmount ?? false) {
      dispatch(addBid(parsedMessage));
    }
  };

  const handleActivateItem = (
    itemId,
    itemEbayIdentifier,
    eventId,
    activationTime,
    bidAmount
  ) => {
    const activeCurrentEvent = localEvents.filter(
      (event) => event.id === eventId
    );
    // console.log({ lastBid, activeCurrentEvent });
    const activeItemIndex = activeCurrentEvent[0].items.findIndex(
      (item) => item.itemEbayIdentifier === itemEbayIdentifier
    );
    if (activeItemIndex) {
      // alert("item activated");
      const updatedEvents = getUpdatedEventsOnItemActiveAndTime(
        localEvents,
        eventId,
        itemId,
        activeCurrentEvent[0],
        activeItemIndex,
        activationTime,
        true,
        true,
          bidAmount
      );
      dispatch(updateCurrentEventItem(updatedEvents));
      console.log("item updated and all disabled", updatedEvents);
    }
  };

  const fetchCurrentBid = async (eventId) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}bids/current?eventId=${eventId}`
    ).then(async (response) => await response.json());
  };

  const handleVisibilityChange = useCallback(async () => {
    if (document.visibilityState === "visible") {
      let currentEventId = undefined;
      let currentItemId = undefined;
      let inProgressEvents = [];
      let updatedEvents = [];

      const fetchInprogressEvents = async () => {
        return await fetch(
          `${process.env.REACT_APP_BASE_URL}events/inProgressEvent`
        ).then(async (response) => await response.json());
      };

      const activateCurrentItem = async (event, currentBid = null) => {
        const lastBidResponse = currentBid
          ? currentBid
          : await fetchCurrentBid(event.id);
        const lastBid = lastBidResponse.data[0];

        if (currentItemId === undefined) {
          currentEventId = lastBid.eventId;
          currentItemId = lastBid.itemEbayIdentifier;
        }

        handleActivateItem(
          lastBid.itemId,
          lastBid.itemEbayIdentifier,
          lastBid.eventId,
          lastBid.lastModifiedDate,
            lastBid.bidAmount
        );

        dispatch(updateLastBid(lastBid));
      };

      // ENDED, UPCOMING, IN_PROGRESS
      
      for (const event of events) {
        const currentEvent = event;
        if (currentEvent && currentEvent.startingTime) {
          const startingTimeStamp = new Date(currentEvent.startingTime.replace('+', ' +')).getTime();
          const currentTimeStamp = new Date().getTime();

          if (
            startingTimeStamp < currentTimeStamp ||
            startingTimeStamp === currentTimeStamp
          ) {
            // Event has started or event has ended

            if (event.status === "UPCOMING") {
              if (!inProgressEvents.length) {
                const inProgressResponse = await fetchInprogressEvents();
                inProgressEvents = inProgressResponse.data;
              }

              if (inProgressEvents.includes(currentEvent.id)) {
                // console.log("event is inprogress");
                currentEvent.status = "IN_PROGRESS";

                await activateCurrentItem(event);
              } else {
                // console.log("event has ended");
                currentEvent.status = "ENDED";
              }
            } else if (event.status === "IN_PROGRESS") {
              const lastBidResponse = await fetchCurrentBid(event.id);
              await activateCurrentItem(event, lastBidResponse);
            }
          } else {
            // console.log("event hasn't started");
            currentEvent.status = "UPCOMING";
          }
          updatedEvents.push(currentEvent);
        }
      }

      let urlParts = window.location.pathname.split("/");
      let urlEventId = urlParts[3];
      let urlItemId = urlParts[5];
      if (urlParts.includes('upcoming-events') && urlParts.includes('item')) {
        if (urlItemId.includes('%7C')) {
          urlItemId = urlItemId.replaceAll("%7C", "|");
        }
        // The following if statement first confirms if the url if of item page, then it checks if we have a currentItem's Id which is in-progress,
        // then it checks if the eventId in the url matches with eventId of the currentItem, and finally it checks if the itemId in the url isn't same as
        // current itemId. Then it proceeds and forwards the use to current item.
        if (currentItemId !== undefined && +urlEventId === +currentEventId && urlItemId != currentItemId) {
          urlParts[3] = currentEventId;
          urlParts[5] = currentItemId;
          if (window.location.pathname !== urlParts.join("/")) {
            window.location.pathname = urlParts.join("/");
          }
        }
      }

      // console.log("updatedEvents", updatedEvents);

      dispatch(updateCurrentEventItem(updatedEvents));
    }
  }, [events, localEvents]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    // eslint-disable-next-line
  }, [handleVisibilityChange]);

  useEffect(() => {
    // The wake lock sentinel.
    let wakeLock = null;

    // Function that attempts to request a screen wake lock.
    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request();
        wakeLock.addEventListener("release", () => {
          console.log("Screen Wake Lock released:", wakeLock.released);
        });
        console.log("Screen Wake Lock released:", wakeLock.released);
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    };

    if (currentEvent && currentEvent.status === "IN_PROGRESS") {
      if (document.visibilityState === "visible") requestWakeLock();

      const handleVisibilityChange = async () => {
        if (wakeLock !== null && document.visibilityState === "visible") {
          await requestWakeLock();
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
    } else {
      window.setTimeout(() => {
        if (wakeLock) {
          wakeLock.release();
          wakeLock = null;
        }
      }, 5000);
    }

    // eslint-disable-next-line
  }, [currentEvent]);

  useEffect(() => {
    setLocalEvents(events);
  }, [events]);

  return (
    <Router>
      <Helmet>
        <html lang={lng} />
      </Helmet>
      <StompWrapper onMessage={onMessage} source="routing" />
      <ScrollToTop />
      <NotificationBar />
      <Sidebar />
      {userError ? <ErrorPopup /> : ""}
      {userAuthFailed ? <ErrorPopupAuthFailed /> : ""}
      <>
        <Header />
        <section className="pages-container">
          <Switch>
            <Route path={`/${lng}`} component={Home} exact />
            <Route
              path={`/${lng}/upcoming-events/:eventId/item/:itemId`}
              component={LoadableVIP}
            />
            <Route
              path={`/${lng}/upcoming-events/:eventId`}
              component={LoadableUpcomingEventsView}
            />
            <Route
              path={`/${lng}/upcoming-events`}
              component={LoadableUpcomingEvents}
            />
            <Route
              path={`/${lng}/event-calendar`}
              component={LoadableEventCalendar}
            />
            <Route path={`/${lng}/about-us`} component={LoadableAboutUs} />
            <Route path={`/${lng}/faq`} component={LoadableFAQ} />
            <Route path={`/${lng}/wishlist`} component={LoadableWishlist} />
            <Route path={`/${lng}/my-auctions`} component={LoadableMyAuctions} />
            <Route path={`/${lng}/livebuy_test`} component={LoadableLiveBuy} />
            <Route path={`/${lng}/lisa_test`} component={LoadableLisaTest} />
            <Route component={NotFound} />
          </Switch>
        </section>
        <Footer />
      </>
    </Router>
  );
};

export default Routing;
