import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';
import React from 'react';

const LoadableHome = loadable(() => import("../../components/Home/Home.js"), {
    fallback: <Loader/>
});

const Home = () => <LoadableHome/>;

export default Home;
