import React from "react";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Link from '../../Language/Link/LanguageLink';

import './LinkSection.scss';

const LinkSection = () => {
    const {t, i18n} = useTranslation('footer');
    const {isMobile, isTablet} = useSelector((state) => state.app.device);

    return (
        <>
        <div className={`link-section${isMobile ? ' mobile' : ''}${isTablet ? ' tablet' : ''}`}>
            <div className="link-wrapper">
                <Link to="/about-us" className="link">
                    {t('about')}
                </Link>
                {i18n.language !== "en" && (
                <a href={t('link.becomeSeller')} className="link" target="_blank" rel="noopener noreferrer">
                    {t('becomeSellers')}
                </a>
                    
                )}
            </div>
            <div className="link-wrapper">
                <Link to="/faq" className="link">
                    {t('FAQ')}
                </Link>
                <a href={t('link.help')} className="link" target="_blank" rel="noopener noreferrer">
                    {t('kontakt')}
                </a>
            </div>
            <div className="little-link-wrapper">
                <div className="link">
                    <a href={t('link.agb')} className="link" target="_blank" rel="noopener noreferrer">
                        {t('AGB')}
                    </a>
                </div>
                <a href={t('link.privacyPolicy')} className="link" target="_blank" rel="noopener noreferrer">
                    {t('privacyPolicy')}
                </a>
                <a href={t('link.imprint')} className="link" target="_blank" rel="noopener noreferrer">
                    {t('impressum')}
                </a>
                <span className="ot-sdk-show-settings">
                    {t('cookieSettings')}
                </span>
            </div>
        </div>
            {
                i18n.language === "fr" ?
                    <div style={{paddingBottom:"10px"}}>
                        Les ventes organisées relèvent du courtage. <a style={{color:"white"}} href={"https://www.ebay.fr/help/policies/member-behaviour-policies/user-agreement?id=4259"} target={"_blank"} rel="noreferrer">Voir conditions</a>
                    </div> :""
            }
        </>
    );
};

export default LinkSection;
