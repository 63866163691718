import React from 'react';
import LinkSection from './LinkSection/LinkSection';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';

import './Footer.scss';

const Footer = () => {
    let vipPage = false;
    if (window.location.href.includes('item')) {
        vipPage = true;
    }
    return (
        <footer>
            <LinkSection/>
            <hr/>
            <div className={`language-switch-wrapper${vipPage ? ' vip-page' : ''}`}>
                <LanguageSwitch/>
            </div>
        </footer>
    );
};

export default Footer;
