import React from 'react';
import Logo from '../Logo/Logo';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {sidebarShow} from '../../../../store/actions/app';
import AccountSidebar from '../../Sidebar/AccountSidebar/AccountSidebar';

import './DesktopHeader.scss';
import Greetings from './Greetings/Greetings';
import NavLink from '../../Language/LanguageNavLink/LanguageNavLink';
import {HEADER_INVERTED} from '../../../../constants';

const DesktopHeader = () => {
    const {t} = useTranslation();
    const {user} = useSelector((state) => state.user);
    const {inverted} = useSelector((state) => state.app.header);
    const dispatch = useDispatch();

    const onClickCheckUser = (e) => {
        if (!user) {
            dispatch(sidebarShow(<AccountSidebar shownMenu={false}/>));
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    };


    return (
        <header className={['desktop', inverted === HEADER_INVERTED ? 'inverted' : null].join(' ')}>
            <div className="container">
                <Logo/>
                <div className="navigation-wrapper">
                    <div className="navigation-left">
                        <nav className="page-nav">
                            <ul>
                                <li><NavLink to="/event-calendar" exact>{t('eventCalendar')}</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="navigation-right">
                        <nav className="user-nav">
                            <ul>
                                <li>
                                    <Greetings user={user}/>
                                </li>
                                <li>
                                    <NavLink to="/wishlist" onClick={onClickCheckUser}>
                                        <span className="my-auctions nav-link">{t('myAuctions')}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/my-auctions" onClick={onClickCheckUser}>
                                        <span className="account nav-link">{t('account')}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default DesktopHeader;
