import { Stomp } from "@stomp/stompjs";
import { WEBSOCKET_LOGIN, WEBSOCKET_PW, WEBSOCKET_URL } from "config/app";
let initialReconnectDelay = 1000;
let currentReconnectDelay = initialReconnectDelay;
let maxReconnectDelay = 16000;

class StompClient {
  static client;
  static isConnecting = false;
  static subscriptionQueues = {};
  constructor() {
    if (!StompClient.client || !StompClient.client.connected) {
      if ((!StompClient.client || !StompClient.client.connected) && !StompClient.isConnecting) {
        StompClient.isConnecting = true;
        this.connect();
        StompClient.isConnecting = false;
      }
    }
  }
  connect() {
    StompClient.client = Stomp.over(() => new WebSocket(WEBSOCKET_URL));
    StompClient.client.debug = () => {};
    // TODO: Move to config

    // exponential back off
    StompClient.client.onWebSocketClose = function () {
      if(currentReconnectDelay < maxReconnectDelay) {
        currentReconnectDelay*=2;
      }
      StompClient.client.reconnectDelay = currentReconnectDelay + Math.floor(Math.random() * 3000);
      console.log(`Websocket connection lost - next connection attempt in ${StompClient.client.reconnectDelay}ms`);
    }

    StompClient.client.heartbeatOutgoing = 20000;
    StompClient.client.heartbeatIncoming = 0;
    let connectArgs = {};
    if (WEBSOCKET_LOGIN && WEBSOCKET_PW) {
      connectArgs = {
        login: WEBSOCKET_LOGIN,
        passcode: WEBSOCKET_PW,
      };
    }
    StompClient.client.connect(connectArgs, this.subscribeToAll.bind(this));
  }

  subscribeToAll(message) {
     for (let destination in StompClient.subscriptionQueues) {
       if (StompClient.subscriptionQueues.hasOwnProperty(destination)) {
         StompClient.client.subscribe(
           destination,
           this.subscriptionReceiver(destination)
         );
       }
     }
  }

  subscribe(source, destination, subscriptionCallback) {
    if (
      StompClient.client.connected &&
      !StompClient.subscriptionQueues[destination]
    ) {
      StompClient.client.subscribe(
        destination,
        this.subscriptionReceiver(destination),
        { id: destination }
      );
    }
    this.addToSubscriptionQueue(source, destination, subscriptionCallback);
  }

  addToSubscriptionQueue(source, destination, subscriptionCallback) {
    if (!StompClient.subscriptionQueues[destination]) {
      StompClient.subscriptionQueues[destination] = [];
    }
    StompClient.subscriptionQueues[destination].push({
      source: source,
      callback: subscriptionCallback,
    });
  }

  subscriptionReceiver(destination) {
    return (message) => {
      if (StompClient.subscriptionQueues[destination]) {
        const parsedMessage = JSON.parse(message.body);
        const result = StompClient.subscriptionQueues[destination].map(
          (subscriptionCallBack) => {
            return subscriptionCallBack.callback(parsedMessage);
          }
        );
        return result;
      }
      throw new Error("No subscriptions for this destination.");
    };
  }

  unsubscribe(source, destination) {
    if (StompClient.subscriptionQueues[destination]) {
      StompClient.subscriptionQueues[destination] =
        StompClient.subscriptionQueues[destination].filter(
          (subscriptionCallBack) => subscriptionCallBack.source !== source
        );
      if (!StompClient.subscriptionQueues[destination].length) {
        //StompClient.client.unsubscribe(destination);
        //StompClient.subscriptionQueues[destination] = null;
      }
    }
  }

  static subscriptionWorker(subscriptionMessageReceiver) {
    return (message) => {
      const parsedMessage = JSON.parse(message.body);
      return subscriptionMessageReceiver(parsedMessage);
    };
  }
}

export default StompClient;
