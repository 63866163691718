import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';

const LoadableAboutUs = loadable(() => import("../../components/AboutUs/AboutUs.js"), {
    fallback: <Loader/>
});

const Imprint = () => <LoadableAboutUs/>;

export default Imprint;
