import React from 'react';
import {NavLink as RouterNavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const NavLink = (props) => {
    const {to, children} = props;
    const {i18n} = useTranslation();

    return (
        <RouterNavLink {...props} to={`/${i18n.language}${to}`}>
            {children}
        </RouterNavLink>
    );
};

export default NavLink;

