import React, { useEffect } from "react";
import { STOMP_DESTINATION } from "../../../constants";
import StompClient from "utils/stomp";

const StompWrapper = ({ children, onMessage, subscriptions, source }) => {
  if (typeof subscriptions === "undefined") {
    subscriptions = {};
    subscriptions[STOMP_DESTINATION] = onMessage;
  }

  useEffect(() => {
    const client = new StompClient();
    for (let subscription in subscriptions) {
      if (subscriptions.hasOwnProperty(subscription)) {
        client.subscribe(source, subscription, subscriptions[subscription]);
      }
    }

    return () => {
      for (let subscription in subscriptions) {
        if (subscriptions.hasOwnProperty(subscription)) {
          client.unsubscribe(source, subscription);
        }
      }
    };
    // eslint-disable-next-line
  }, []);

  return <div>{children}</div>;
};

export default React.memo(StompWrapper);
