import React from 'react';
import {useSelector} from 'react-redux';
import Link from '../../Language/Link/LanguageLink';
import {HEADER_INVERTED} from '../../../../constants';

import './Logo.scss';
import logo from '../../../../assets/svg/livebyebay-white_turquoise.svg';
import logoInverted from '../../../../assets/svg/livebyebay-white_petrol.svg';

const Logo = () => {
    const {inverted} = useSelector((state) => state.app.header);
    let renderLogo = logo;
    if (inverted === HEADER_INVERTED) {
        renderLogo = logoInverted;
    }
    return (
        <div className="logo-wrapper">
            <Link to="/">
                <img src={renderLogo} alt="Logo" height={logo.height} />
            </Link>
        </div>
    );
};

export default Logo;
