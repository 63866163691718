// App action types
export const WINDOW_RESIZE = "WINDOW_RESIZE";
export const WINDOW_SCROLL = "WINDOW_SCROLL";
export const SIDEBAR_SHOW = "SIDEBAR_SHOW";
export const SIDEBAR_HIDE = "SIDEBAR_HIDE";
export const NOTIFICATION_BAR_SHOW = "NOTIFICATION_BAR_SHOW";
export const NOTIFICATION_BAR_HIDE = "NOTIFICATION_BAR_HIDE";

export const FETCH_PUBLISHED_EVENTS = "FETCH_PUBLISHED_EVENTS";
export const FETCH_PUBLISHED_EVENTS_START = "FETCH_PUBLISHED_EVENTS_START";
export const FETCH_PUBLISHED_EVENTS_SUCCESS = "FETCH_PUBLISHED_EVENTS_SUCCESS";
export const FETCH_PUBLISHED_EVENTS_FAILED = "FETCH_PUBLISHED_EVENTS_FAILED";

export const FETCH_ALL_EVENTS = "FETCH_ALL_EVENTS";
export const FETCH_ALL_EVENTS_START = "FETCH_ALL_EVENTS_START";
export const FETCH_ALL_EVENTS_SUCCESS = "FETCH_ALL_EVENTS_SUCCESS";
export const FETCH_ALL_EVENTS_FAILED = "FETCH_ALL_EVENTS_FAILED";

export const FETCH_EVENT_ITEMS = "FETCH_EVENT_ITEMS";
export const FETCH_EVENT_ITEMS_START = "FETCH_EVENT_ITEMS_START";
export const FETCH_EVENT_ITEMS_SUCCESS = "FETCH_EVENT_ITEMS_SUCCESS";
export const FETCH_EVENT_ITEMS_FAILED = "FETCH_EVENT_ITEMS_FAILED";

export const FETCH_ITEM_DESCRIPTION = "FETCH_ITEM_DESCRIPTION";
export const FETCH_ITEM_DESCRIPTION_START = "FETCH_ITEM_DESCRIPTION_START";
export const FETCH_ITEM_DESCRIPTION_SUCCESS = "FETCH_ITEM_DESCRIPTION_SUCCESS";
export const FETCH_ITEM_DESCRIPTION_FAILED = "FETCH_ITEM_DESCRIPTION_FAILED";

export const UPDATE_EVENT = "UPDATE_EVENT";

export const FOLLOW_ITEM = "FOLLOW_ITEM";

export const CHANGE_HEADER = "CHANGE_HEADER";

export const UPDATE_EVENT_DATA = "UPDATE_EVENT_DATA";
export const ACTIVATE_CURRENT_EVENT_ITEM = "ACTIVATE_CURRENT_EVENT_ITEM";

// User type
export const USER_FETCH_LOGIN_URL = "USER_FETCH_LOGIN_URL";
export const USER_FETCH_LOGIN_URL_SUCCESS = "USER_FETCH_LOGIN_URL_SUCCESS";
export const USER_FETCH_LOGIN_URL_FAILURE = "USER_FETCH_LOGIN_URL_FAILURE";
export const USER_FETCH_TOKEN = "USER_FETCH_TOKEN";
export const USER_FETCH_TOKEN_SUCCESS = "USER_FETCH_TOKEN_SUCCESS";
export const USER_FETCH_TOKEN_FAILURE = "USER_FETCH_TOKEN_FAILURE";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const USER_LOG_OUT_SUCCESS = "USER_LOG_OUT_SUCCESS";
export const USER_LOG_OUT_FAILURE = "USER_LOG_OUT_FAILURE";
export const USER_SUBSCRIBE_NEWSLETTER = "USER_SUBSCRIBE_NEWSLETTER";
export const USER_SUBSCRIBE_NEWSLETTER_SUCCESS =
  "USER_SUBSCRIBE_NEWSLETTER_SUCCESS";
export const USER_SUBSCRIBE_NEWSLETTER_FAILURE =
  "USER_SUBSCRIBE_NEWSLETTER_FAILURE";
export const USER_DECLINE_NEWSLETTER = "USER_DECLINE_NEWSLETTER";
export const USER_DECLINE_NEWSLETTER_SUCCESS =
  "USER_DECLINE_NEWSLETTER_SUCCESS";
export const USER_DECLINE_NEWSLETTER_FAILURE =
  "USER_DECLINE_NEWSLETTER_FAILURE";
export const USER_FETCH_AUCTIONS = "USER_FETCH_AUCTIONS";
export const USER_FETCH_AUCTIONS_SUCCESS = "USER_FETCH_AUCTIONS_SUCCESS";
export const USER_FETCH_AUCTIONS_FAILURE = "USER_FETCH_AUCTIONS_FAILURE";
export const RESET_USER_ERROR_POPUP = "RESET_USER_ERROR_POPUP";
// Bid
export const FETCH_CURRENT_BID = "FETCH_CURRENT_BID";
export const FETCH_CURRENT_BID_SUCCESS = "FETCH_CURRENT_BID_SUCCESS";
export const FETCH_CURRENT_BID_FAILED = "FETCH_CURRENT_BID_FAILURE";

//place Bid
export const PLACE_BID = "PLACE_BID";
export const PLACE_BID_SUCCESS = "PLACE_BID_SUCCESS";
export const PLACE_BID_FAILED = "PLACE_BID_FAILURE";
export const ADD_BID = "ADD_BID";
export const ADD_WINNING_BID = "ADD_WINNING_BID";
export const RESET_BIDS = "RESET_BIDS";
export const BID_NOT_CONFIRMED = "BID_NOT_CONFIRMED";
export const ACTIVATION_TIME = "ACTIVATION_TIME";
export const UPDATE_CURRENT_BID = "UPDATE_CURRENT_BID";

// Buy
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const ADD_ITEM_TO_CART_SUCCESS = "ADD_ITEM_TO_CART_SUCCESS";
export const ADD_ITEM_TO_CART_FAILURE = "ADD_ITEM_TO_CART_FAILURE";

export const SWAGGER = "SWAGGER";

export const FETCH_WISHLIST = "FETCH_WISHLIST";
export const FETCH_WISHLIST_START = "FETCH_WISHLIST_START";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_WISHLIST_FAILED = "FETCH_WISHLIST_FAILED";

export const WISHLIST_EVENT_ACTIONS = "WISHLIST_EVENT_ACTIONS";
export const WISHLIST_EVENT_ACTIONS_START = "WISHLIST_EVENT_ACTIONS_START";
export const WISHLIST_EVENT_ACTIONS_SUCCESS = "WISHLIST_EVENT_ACTIONS_SUCCESS";
export const WISHLIST_EVENT_ACTIONS_FAILED = "WISHLIST_EVENT_ACTIONS_FAILED";

export const WISHLIST_ITEM_ACTIONS = "WISHLIST_ITEM_ACTIONS";
export const WISHLIST_ITEM_ACTIONS_START = "WISHLIST_ITEM_ACTIONS_START";
export const WISHLIST_ITEM_ACTIONS_SUCCESS = "WISHLIST_ITEM_ACTIONS_SUCCESS";
export const WISHLIST_ITEM_ACTIONS_FAILED = "WISHLIST_ITEM_ACTIONS_FAILED";

export const FETCH_TRANSACTION_STATUS = "FETCH_TRANSACTION_STATUS";
export const FETCH_TRANSACTION_STATUS_START = "FETCH_TRANSACTION_STATUS_START";
export const FETCH_TRANSACTION_STATUS_SUCCESS =
  "FETCH_TRANSACTION_STATUS_SUCCESS";
export const FETCH_TRANSACTION_STATUS_FAILED =
  "FETCH_TRANSACTION_STATUS_FAILED";
