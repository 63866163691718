import loadable from '@loadable/component';
import Loader from '../../components/SharedComponents/Loader/Loader';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';

const LoadableLisaTest = loadable(() => import("../../components/LisaTest/LisaTest.js"), {
    fallback: <Loader/>
});

const LisaTest = () => {
    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('metaTitlePage', {page: 'Lisa Test'})}</title>
            </Helmet>
            <LoadableLisaTest/>
        </>
    );
};

export default LisaTest;
