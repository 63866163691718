import React from 'react';
import './Header.scss';
import {useSelector} from 'react-redux';
import DesktopHeader from './DesktopHeader/DesktopHeader';
import MobileHeader from './MobileHeader/MobileHeader';

const Header = () => {
    const {isMobile, isTablet} = useSelector((state) => state.app.device)
    let header;
    if (!isTablet && !isMobile) {
        header =  <DesktopHeader/>;
    } else {
        header = <MobileHeader/>;
    }

    return (
        <>
            {header}
        </>
    );

};

export default Header;
