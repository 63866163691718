import {ADD_ITEM_TO_CART, ADD_ITEM_TO_CART_SUCCESS, ADD_ITEM_TO_CART_FAILURE,} from '../actions/actionTypes';
import Auth from '../../utils/auth';

const initialState = {
    isFetching: false
};
const auth = new Auth();

const buyReducer = (state = initialState, action) => {
    auth.checkActionForAuthFailure(action);
    switch (action.type) {
        case ADD_ITEM_TO_CART:
            return {
                ...initialState,
                isFetching: true
            }
        case ADD_ITEM_TO_CART_SUCCESS:
            const {languageCode, eventId} = action;
            const eventUrl = `/${languageCode}/upcoming-events/${eventId}`;
            if (window.location.pathname !== eventUrl) window.location.href = eventUrl;
            return {
                ...initialState,
                isFetching: false
            }
        case ADD_ITEM_TO_CART_FAILURE:
            return {
                ...initialState,
                isFetching: false
            }
        default:
            return state;
    }
};

export default buyReducer;
