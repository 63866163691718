import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Loader from './components/SharedComponents/Loader/Loader';
import {HelmetProvider} from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
      <HelmetProvider>
          <Provider store={store}>
              <Suspense fallback={<Loader/>}>
                <App />
              </Suspense>
          </Provider>
      </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
