import {
    FETCH_TRANSACTION_STATUS,
    FETCH_TRANSACTION_STATUS_START,
    FETCH_TRANSACTION_STATUS_SUCCESS,
    FETCH_TRANSACTION_STATUS_FAILED,
} from '../actions/actionTypes';
import Auth from '../../utils/auth';

const initialState = {
    transactionStatus: "",
};
const auth = new Auth();

const transactionStatusReducer = (state = initialState, action) => {
    auth.checkActionForAuthFailure(action);
    switch (action.type) {
        case FETCH_TRANSACTION_STATUS:
        case FETCH_TRANSACTION_STATUS_START:
            return {
                ...state,
            };
        case FETCH_TRANSACTION_STATUS_SUCCESS:
            const transactionStatus = action.result.data ?? "";
            return {
                ...state,
                transactionStatus: transactionStatus
            };
        case FETCH_TRANSACTION_STATUS_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default transactionStatusReducer;
