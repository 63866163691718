import loadable from '@loadable/component';
import Loader from '../../components/SharedComponents/Loader/Loader';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';

const LoadableMyAuctions = loadable(() => import("../../components/MyAuctions/MyAuctions.js"), {
    fallback: <Loader/>
});

const MyAuctions = () => {
    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('metaTitlePage', {page: t('myAuctions')})}</title>
            </Helmet>
            <LoadableMyAuctions/>
        </>
    );
};

export default MyAuctions;
