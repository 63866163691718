import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {userLogOut} from '../../../../../store/actions/user';

import './Greetings.scss';

const Greetings = ({user}) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const logOut = () => {
        dispatch(userLogOut());
    };

    if (!user) return null;

    return (
        <div className="greetings-wrapper">
            <div className={['greetings', show ? 'show' : ''].join(' ')}>
                <div className="greetings-name" onClick={() => setShow(!show)}>
                    <div>{t('hi', {name: user.userId})}</div><span className={['down-arrow', show ? 'show' : null].join(' ')}>Down</span></div>
                {show ? (
                    <div className="greetings-bye">
                        <span className="greetings-bye-bye" onClick={logOut}>
                            {t('logOut')}
                        </span>
                    </div>
                ) : ''}
            </div>
        </div>
    );
};

export default Greetings;
