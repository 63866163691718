import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const Link = (props) => {
    const {to, children} = props;
    const {i18n} = useTranslation();

    return (
        <RouterLink {...props} to={`/${i18n.language}${to}`}>
            {children}
        </RouterLink>
    );
};

export default Link;

