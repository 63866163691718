import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {sidebarHide} from '../../../../store/actions/app';
import {fetchUserLoginUrl, userLogOut} from '../../../../store/actions/user';
import Button from '../../Button/Button';
import NavLink from '../../Language/LanguageNavLink/LanguageNavLink';

import './AccountSidebar.scss';

const AccountSidebar = ({shownMenu}) => {
    const {t, i18n} = useTranslation();
    const {user} = useSelector((state) => state.user);
    const {isMobile, isTablet} = useSelector((state) => state.app.device);
    const [showMenu, setShowMenu] = useState(shownMenu ?? true);
    const dispatch = useDispatch();

    const onLoginClick = () => {
        dispatch(fetchUserLoginUrl(i18n.language));
    };

    const onLogoutClick = () => {
        dispatch(userLogOut());
    };

    const onMenuItemClick = (e) => {
        if ((isMobile || isTablet) && !user) {
            setShowMenu(false);
            e.stopPropagation();
            e.preventDefault();
            return false;
        } else {
            dispatch(sidebarHide());
        }
    };

    return (
        <div className="account-sidebar">
            {showMenu ? (
                <div className="account-sidebar-navigation">
                    <ul>
                        <li>
                            <NavLink to="/my-auctions" onClick={onMenuItemClick}>{t('myAuctions')}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/event-calendar" onClick={() => dispatch(sidebarHide())}>{t('eventCalendar')}</NavLink>
                        </li>
                    </ul>
                </div>
            ): null}
            {!showMenu ? (
                <div className="login-description">
                    {isTablet || isMobile ? (
                        <span className="login-back" onClick={() => setShowMenu(true)}>{t('back')}</span>
                    ) : null}
                    <h3>{t('sidebar.notLoggedInYet')}</h3>
                    {t('sidebar.pleaseLogin')}
                </div>
            ): null}
            {
                !user ? (
                    <div className="login-buttons">
                        <Button onClick={onLoginClick}>{t('loginWithEbay')}</Button>
                        <Button onClick={onLoginClick}>{t('register')}</Button>
                    </div>
                ) : (
                    <div className="user-log-out">
                        <span className="user-name">{t('hi', {name: user.name})}</span>
                        <br />
                        <span className="user-logout" onClick={onLogoutClick}>{t('logOut')}</span>
                    </div>
                )
            }
        </div>
    );
};

export default AccountSidebar;
