import {
  FETCH_CURRENT_BID,
  FETCH_CURRENT_BID_SUCCESS,
  FETCH_CURRENT_BID_FAILED,
  PLACE_BID,
  PLACE_BID_SUCCESS,
  PLACE_BID_FAILED,
  ADD_BID,
  ADD_WINNING_BID,
  RESET_BIDS,
  ACTIVATION_TIME,
  UPDATE_CURRENT_BID,
} from "../actions/actionTypes";
import Auth from "../../utils/auth";

const initialState = {
  lastBid: null,
  firstBid: false,
  confirmBidSuccess: false,
  currentBid: 0,
  winningBid: 0,
  numberOfBids: 0,
  activationTimeValue: null,
  itemId: null,
  eventId: null,
  bids: [],
};
const auth = new Auth();

const bidReducer = (state = initialState, action) => {
  auth.checkActionForAuthFailure(action);
  switch (action.type) {
    case RESET_BIDS:
      return {
        ...initialState,
      };
    case PLACE_BID:
    case FETCH_CURRENT_BID:
      return {
        ...state,
        eventId: action.eventId,
        itemId: action.itemId,
      };
    case FETCH_CURRENT_BID_SUCCESS:
      const lastBid = action.result.data[0] ?? false;
      if (!lastBid || !lastBid.bidAmount) {
        return {
          ...state,
          bids: [],
          lastBid: null,
          winningBid: 0,
          currentBid: 0,
          numberOfBids: 0,
        };
      }
      if (lastBid.numberOfbids === 0) {
        return {
          ...state,
          bids: [lastBid],
          lastBid: lastBid,
          currentBid: lastBid.bidAmount,
          numberOfBids: lastBid.numberOfbids,
          activationTimeValue: lastBid.lastModifiedDate,
        };
      }
      return {
        ...state,
        bids: [lastBid],
        lastBid: lastBid,
        currentBid: lastBid.bidAmount,
        activationTimeValue: lastBid.lastModifiedDate,
        numberOfBids: lastBid.numberOfbids,
      };
    case PLACE_BID_SUCCESS:
      const responseMessage = action.result.responseMessage ?? false;
      if (responseMessage === "Processed Successfully") {
        return {
          ...state,
          currentBid: action.bidAmount,
          winningBid: action.bidAmount,
          confirmBidSuccess: true,
        };
      }
      return {
        ...state,
      };
    case PLACE_BID_FAILED:
      return {
        ...state,
      };
    case FETCH_CURRENT_BID_FAILED:
      return {
        ...state,
      };
    case ADD_BID:
      const numberOfBids = action.bid.numberOfBids ?? false;
      if (!numberOfBids) {
        return {
          ...state,
          bids: [...state.bids, action.bid],
          lastBid: action.bid,
          currentBid: action.bid.bidAmount,
          activationTimeValue: action.bid.bidDateTime,
          numberOfBids: 0,
        };
      }
      return {
        ...state,
        bids: [...state.bids, action.bid],
        lastBid: action.bid,
        currentBid: action.bid.bidAmount,
        numberOfBids: action.bid.numberOfBids,
        activationTimeValue: action.bid.bidDateTime,
      };
    case ACTIVATION_TIME:
      return {
        ...state,
        activationTimeValue: action.activationTime,
      };
    case ADD_WINNING_BID:
      return {
        ...state,
        winningBid: action.bid,
      };
    case UPDATE_CURRENT_BID:
      if (!action.lastBid || !action.lastBid.bidAmount) {
        return {
          ...state,
          bids: [],
          lastBid: null,
          winningBid: 0,
          currentBid: 0,
          numberOfBids: 0,
        };
      }
      if (action.lastBid.numberOfbids === 0) {
        return {
          ...state,
          bids: [action.lastBid],
          lastBid: action.lastBid,
          currentBid: action.lastBid.bidAmount,
          numberOfBids: action.lastBid.numberOfbids,
        };
      }
      return {
        ...state,
        bids: [action.lastBid],
        lastBid: action.lastBid,
        currentBid: action.lastBid.bidAmount,
        activationTimeValue: action.lastBid.lastModifiedDate,
        numberOfBids: action.lastBid.numberOfbids,
      };
    default:
      return state;
  }
};

export default bidReducer;
