import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';

const LoadableUpcomingEvents = loadable(() => import("../../components/UpcomingEvents/UpcomingEvents.js"), {
    fallback: <Loader/>
});

const UpcomingEvents = () => {
    const {t} = useTranslation(['upcomingEvents', 'translation']);
    return (
        <>
            <Helmet>
                <title>{t('translation:metaTitlePage', {page: t('title')})}</title>
            </Helmet>
            <LoadableUpcomingEvents/>
        </>
    );
}


export default UpcomingEvents;
