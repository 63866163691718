import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sidebarHide} from '../../../store/actions/app';
import {useTranslation} from 'react-i18next';

import closePetrol from '../../../assets/svg/close-petrol.svg';
import closeWhite from '../../../assets/svg/close-white.svg';

import './Sidebar.scss';

const Sidebar = () => {
    const {t} = useTranslation();
    const {sidebar, device} = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const onCloseClick = () => {
        dispatch(sidebarHide());
    }

    let closeImage = closePetrol;
    if (device.isMobile || device.isTablet) {
        closeImage = closeWhite;
    }

    if (!sidebar) return null;
    return (
        <div className="sidebar-overlay">
            <div className="sidebar-wrapper">
                <div className="sidebar-header">
                    <span className="sidebar-close" onClick={onCloseClick}>
                        <img src={closeImage} alt={t('close')} />
                    </span>
                </div>
                {sidebar}
            </div>
        </div>
    );
};

export default Sidebar;
