import axios from 'axios';
import Auth from '../../utils/auth';

const AxiosMiddleware = (opts) => {
    const auth = new Auth();
    const axiosInstance = axios.create({
        baseURL: opts.baseURL,
    });

    axiosInstance.interceptors.request.use(
        async (config) => {
            if (opts.requestInterceptor) {
                config = opts.requestInterceptor(config);
            }

            const authHeader = await auth.checkAuthorization();
            if (authHeader) {
                config.headers['Authorization'] = authHeader;
            } else {
                config.headers['Authorization'] = "false";
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            if (opts.responseInterceptor) {
                response = opts.responseInterceptor(response);
            }
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return ({ dispatch, getState }) =>
        (next) =>
        (action) => {
            if (typeof action === 'function') {
                return action(dispatch, getState);
            }
            if (!action.axios) {
                return next(action);
            }

            const { types, axios: callApi, ...rest } = action;
            const [REQUEST, SUCCESS, FAILURE] = types;

            const fetchData = async () => {
                if (typeof REQUEST === 'function') next(REQUEST(rest));
                else if (REQUEST) next({ ...rest, type: REQUEST });

                try {
                    const response = await callApi(axiosInstance);
                    if (typeof SUCCESS === 'function') {
                        next(SUCCESS({ ...rest, result: response.data }));
                    } else if (SUCCESS !== undefined) {
                        next({ ...rest, result: response.data, type: SUCCESS });
                    }
                } catch (error) {
                    if (typeof FAILURE === 'function') {
                        next(FAILURE({ ...rest, error }));
                    } else if (FAILURE !== undefined) {
                        next({ ...rest, error, type: FAILURE });
                    }
                }
            };

            return fetchData();
        };
};

export default AxiosMiddleware;
