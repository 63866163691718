import loadable from '@loadable/component';
import Loader from '../../components/SharedComponents/Loader/Loader';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';

const LoadableLiveBuy = loadable(() => import("../../components/LiveBuy/LiveBuy.js"), {
    fallback: <Loader/>
});

const LiveBuy = () => {
    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('metaTitlePage', {page: 'LiveBuy'})}</title>
            </Helmet>
            <LoadableLiveBuy/>
        </>
    );
};

export default LiveBuy;
