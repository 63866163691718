import { ENDED, IN_PROGRESS, UPCOMING } from "../constants";
import { parseDate } from "./date";

export const getStandardUpcomingEvents = (events) => {
  return getSortedUpcomingEvents(events).filter(
    (event) => event.status === UPCOMING && !event.mainEvent
  );
};

export const getTopUpcomingEvents = (events) => {
  return getSortedUpcomingEvents(events).filter(
    (event) => event.status === UPCOMING && event.mainEvent
  );
};

export const getSortedUpcomingEvents = (events) =>
  getSortedEvents(
    events.filter((event) => parseDate(event.startingTime) - new Date() > 0)
  );

export const getSortedActiveEvents = (events) => {
  const topEvents = events.filter(
    (event) => event.status === IN_PROGRESS && event.mainEvent
  );
  const standardEvents = events.filter(
    (event) => event.status === IN_PROGRESS && !event.mainEvent
  );
  return getSortedEvents(topEvents.length ? topEvents : standardEvents);
};

export const getSortedEvents = (events) =>
  events.sort((a, b) => {
    return parseDate(a.startingTime) - parseDate(b.startingTime);
  });

export const getUpcomingEventItems = (items) => {
  return items.filter((item) => !item.active && !item.sold);
};

export const getNotEndedEvents = (events) => {
  return events.filter((event) => event.status !== ENDED);
};

export const getGroupedEventsByDay = (events) => {
  const sortedEvents = getSortedEvents(events);
  let currentGroup;
  const groupedEvents = [];
  let groupedIndex = -1;

  for (let i = 0; i < sortedEvents.length; i++) {
    const eventDate = parseDate(events[i].startingTime);
    const group = `${eventDate.getDate()}.${eventDate.getMonth()}`;
    if (!currentGroup || currentGroup !== group) {
      currentGroup = group;
      groupedIndex++;
      groupedEvents[groupedIndex] = [events[i]];
    } else {
      groupedEvents[groupedIndex].push(events[i]);
    }
  }
  return groupedEvents;
};

const getEventIndex = (events, eventId) =>
  events.findIndex((event) => event.id === parseInt(eventId, 10));

export const getCurrentItemIndex = (currentEvent, itemId) =>
  currentEvent &&
  currentEvent.items &&
  currentEvent.items.length > 0 &&
  currentEvent.items.findIndex((item) => item.itemEbayIdentifier === itemId);

export const getUpdatedEventStatus = (currentEventCopy, currentItemIndex) => {
  if (currentItemIndex === currentEventCopy.items.length - 1) {
    return ENDED;
  }
  return IN_PROGRESS;
};

export const getUpdatedEvenstOnItemEnd = (
  events,
  eventId,
  currentEvent,
  itemId,
  itemIndex
) => {
  const eventsCopy = [...events];
  const currentEventCopy = currentEvent && Object.assign({}, currentEvent);
  const eventIndex = getEventIndex(events, eventId);

  const endedItemIndex = getCurrentItemIndex(currentEventCopy, itemId);
  const endedItem = currentEventCopy.items[endedItemIndex];
  endedItem.deactivationTime = new Date().getTime();
  endedItem.active = false;
  endedItem.sold = true;

  currentEventCopy.items[endedItemIndex] = endedItem;
  currentEventCopy.status = getUpdatedEventStatus(
    currentEventCopy,
    endedItemIndex
  );

  eventsCopy[eventIndex] = currentEventCopy;
  return eventsCopy;
};

export const getUpdatedEventsOnItemActive = (
  events,
  eventId,
  itemId,
  currentEvent,
  itemIndex,
  disableOtherItems = false
) => {
  const eventsCopy = [...events];
  const currentEventCopy = currentEvent && Object.assign({}, currentEvent);
  const eventIndex = getEventIndex(events, eventId);

  if (disableOtherItems) {
    const deactivatedItems = currentEventCopy.items.map((item) => {
      item.active = false;
      return item;
    });
    currentEventCopy.items = deactivatedItems;
  }

  const activeItemIndex =
    itemIndex !== null || itemIndex !== undefined
      ? itemIndex
      : getCurrentItemIndex(currentEventCopy, itemId);
  const activeItem =
    currentEventCopy && currentEventCopy.items[activeItemIndex];
  activeItem.active = true;
  activeItem.sold = false;
  currentEventCopy.items[activeItemIndex] = activeItem;
  currentEventCopy.status = IN_PROGRESS;

  eventsCopy[eventIndex] = currentEventCopy;
  return eventsCopy;
};

export const getUpdatedEventsOnItemActiveAndTime = (
  events,
  eventId,
  itemId,
  currentEvent,
  itemIndex,
  activationTimeValue,
  disableOtherItems = false,
  soldPrevItems = false,
  bidAmount
) => {
  const eventsCopy = [...events];
  const currentEventCopy = currentEvent && Object.assign({}, currentEvent);
  const eventIndex = getEventIndex(events, eventId);

  if (disableOtherItems) {
    const deactivatedItems = currentEventCopy.items.map((item) => {
      item.active = false;
      return item;
    });
    currentEventCopy.items = deactivatedItems;
  }

  if (soldPrevItems) {
    const soldItems = currentEventCopy.items.map((item, index) => {
      if (index < itemIndex) {
        item.sold = true;
        return item;
      }
      return item;
    });
    currentEventCopy.items = soldItems;
  }

  const activeItemIndex =
    itemIndex !== null || itemIndex !== undefined
      ? itemIndex
      : getCurrentItemIndex(currentEventCopy, itemId);
  const activeItem =
    currentEventCopy && currentEventCopy.items[activeItemIndex];

  activeItem.active = true;
  activeItem.activationTime = activationTimeValue;
  activeItem.sold = false;
  if (activeItem.bids && activeItem.bids.length) {
    activeItem.bids[activeItem.bids.length-1].bidAmount = bidAmount
  }
  currentEventCopy.items[activeItemIndex] = activeItem;
  currentEventCopy.status = IN_PROGRESS;

  eventsCopy[eventIndex] = currentEventCopy;
  return eventsCopy;
};
