import API_URLS from 'config/apiUrls';
import {
  FETCH_CURRENT_BID,
  FETCH_CURRENT_BID_SUCCESS,
  FETCH_CURRENT_BID_FAILED,
  SWAGGER,
  PLACE_BID,
  PLACE_BID_SUCCESS,
  PLACE_BID_FAILED,
  ADD_BID,
  ADD_WINNING_BID,
  RESET_BIDS,
  ACTIVATION_TIME,
  UPDATE_CURRENT_BID,
} from "./actionTypes";

// export const fetchLastBid = (eventId, itemId) => {
//   return {
//     type: SWAGGER,
//     types: [
//       FETCH_CURRENT_BID,
//       FETCH_CURRENT_BID_SUCCESS,
//       FETCH_CURRENT_BID_FAILED,
//     ],
//     swagger: (api, authHeader) => {
//       return api["bid-controller"].getCurrentBidForItemUsingGET({
//         ...authHeader,
//         eventId: eventId,
//         itemId: itemId,
//       });
//     },
//     eventId: eventId,
//     itemId: itemId,
//   };
// };

export const fetchLastBid = (eventId, itemId) => {
    return {
        types: [
            FETCH_CURRENT_BID,
            FETCH_CURRENT_BID_SUCCESS,
            FETCH_CURRENT_BID_FAILED,
        ],
        axios: async (axiosInstance) => {
           const requestURL = `${API_URLS.getLastBid}?eventId=${eventId}&itemId=${itemId}`;
            return await axiosInstance.get(requestURL);
        },
        eventId: eventId,
        itemId: itemId,
    };
};

export const updateLastBid = (bid) => {
  return {
    type: UPDATE_CURRENT_BID,
    lastBid: bid,
  };
};

export const addBid = (bid) => {
  return {
    type: ADD_BID,
    bid: bid,
  };
};

export const resetBids = () => {
  return {
    type: RESET_BIDS,
  };
};

export const addWinningBid = (bid) => {
  return {
    type: ADD_WINNING_BID,
    bid: bid,
  };
};

// export const placeBid = (
//   bidAmount,
//   eventId,
//   itemId,
//   bidIsConfirmed,
//   languageCode
// ) => {
//   return {
//     type: SWAGGER,
//     types: [PLACE_BID, PLACE_BID_SUCCESS, PLACE_BID_FAILED],
//     swagger: (api, authHeader) => {
//       return api["bid-controller"].placeBidUsingPOST({
//         ...authHeader,
//         bidAmount: bidAmount,
//         eventId: eventId,
//         itemId: itemId,
//         confirmBid: bidIsConfirmed,
//         languageCode: languageCode,
//       });
//     },
//     bidAmount: bidAmount,
//   };
// };

export const placeBid = (
    bidAmount,
    eventId,
    itemId,
    bidIsConfirmed,
    languageCode
) => {
    return {
        types: [PLACE_BID, PLACE_BID_SUCCESS, PLACE_BID_FAILED],
        axios: async (axiosInstance) => {
            const requestURL = `${API_URLS.placeBid}?bidAmount=${bidAmount}&eventId=${eventId}&itemId=${itemId}&confirmBid=${bidIsConfirmed}&languageCode=${languageCode}`;
            return await axiosInstance.post(requestURL);
        },
        bidAmount: bidAmount,
    };
};

export const activationTime = (activationTime) => {
  return {
    type: ACTIVATION_TIME,
    activationTime: activationTime,
  };
};
