import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';

const LoadableFAQ = loadable(() => import("../../components/FAQ/FAQ.js"), {
    fallback: <Loader/>
});

const FAQ = () => <LoadableFAQ/>;

export default FAQ;
