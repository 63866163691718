import API_URLS from 'config/apiUrls';
import {
  FETCH_PUBLISHED_EVENTS,
  FETCH_PUBLISHED_EVENTS_START,
  FETCH_PUBLISHED_EVENTS_SUCCESS,
  FETCH_PUBLISHED_EVENTS_FAILED,
  FETCH_ALL_EVENTS_START,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_ALL_EVENTS_FAILED,
  FETCH_EVENT_ITEMS_START,
  FETCH_EVENT_ITEMS_SUCCESS,
  FETCH_EVENT_ITEMS_FAILED,
  UPDATE_EVENT,
  UPDATE_EVENT_DATA,
  FETCH_ITEM_DESCRIPTION,
  FETCH_ITEM_DESCRIPTION_FAILED,
  FETCH_ITEM_DESCRIPTION_SUCCESS,
  FETCH_ITEM_DESCRIPTION_START,
  ACTIVATE_CURRENT_EVENT_ITEM,
} from "./actionTypes";

// export const fetchPublishedEvents = (lng, eventId) => {
//   return {
//     type: FETCH_PUBLISHED_EVENTS,
//     types: [
//       FETCH_PUBLISHED_EVENTS_START,
//       FETCH_PUBLISHED_EVENTS_SUCCESS,
//       FETCH_PUBLISHED_EVENTS_FAILED,
//     ],
//     runOnce: `${FETCH_PUBLISHED_EVENTS}_${lng}_${eventId}`,
//     swagger: (api, authHeader) => {
//       const params = { ...authHeader, languageCode: lng };
//       if (eventId) {
//         params.eventId = eventId;
//       }
//       return api["event-controller"].getAllPublishedEventsUsingGET(params);
//     },
//     lng: lng,
//     eventId: eventId,
//   };
// };

export const fetchPublishedEvents = (lng, eventId) => {
    return {
        type: FETCH_PUBLISHED_EVENTS,
        types: [
            FETCH_PUBLISHED_EVENTS_START,
            FETCH_PUBLISHED_EVENTS_SUCCESS,
            FETCH_PUBLISHED_EVENTS_FAILED,
        ],
        runOnce: `${FETCH_PUBLISHED_EVENTS}_${lng}_${eventId}`,
        axios: async (axiosInstance) => {
            const params = { languageCode: lng };
            if (eventId) {
                params.eventId = eventId;
            }
            return await axiosInstance.get(API_URLS.getAllPublishedEvents, {
                params: params,
            });
        },
        lng: lng,
        eventId: eventId,
    };
};

// export const fetchAllEvents = (lng) => {
//   return {
//     types: [
//       FETCH_ALL_EVENTS_START,
//       FETCH_ALL_EVENTS_SUCCESS,
//       FETCH_ALL_EVENTS_FAILED,
//     ],
//     swagger: (api, authHeader) => {
//       return api["event-controller"].getAllEventsUsingGET({
//         ...authHeader,
//         languageCode: lng,
//       });
//     },
//     lng: lng,
//   };
// };

export const fetchAllEvents = (lng) => {
    return {
        types: [
            FETCH_ALL_EVENTS_START,
            FETCH_ALL_EVENTS_SUCCESS,
            FETCH_ALL_EVENTS_FAILED,
        ],
        axios: async (axiosInstance) => {
            return await axiosInstance.get(API_URLS.getAllEvents, {
                params: {
                    languageCode: lng,
                },
            });
        },
        lng: lng,
    };
};



// export const fetchEventItems = (lng, eventId) => {
//     return {
//         types: [
//             FETCH_EVENT_ITEMS_START,
//             FETCH_EVENT_ITEMS_SUCCESS,
//             FETCH_EVENT_ITEMS_FAILED,
//         ],
//         swagger: (api, authHeader) => {
//             return api['event-controller'].getItemsForEventUsingGET({
//                 ...authHeader,
//                 lng: lng,
//                 eventId: parseInt(eventId, 10),
//             });
//         },
//     };
// };

// export const fetchEventItems = (lng, eventId) => {
//     return {
//         types: [
//             FETCH_EVENT_ITEMS_START,
//             FETCH_EVENT_ITEMS_SUCCESS,
//             FETCH_EVENT_ITEMS_FAILED,
//         ],
//         axios: (axiosInstance) => {
//             return axiosInstance.get(API_URLS.getEventItems, {
//                 params: {
//                     lng: lng,
//                     eventId: parseInt(eventId, 10),
//                 },
//             });
//         },
//     };
// };

// export const fetchItemDescription = (lng, eventId, itemId) => {
//   return {
//     type: FETCH_ITEM_DESCRIPTION,
//     types: [
//       FETCH_ITEM_DESCRIPTION_START,
//       FETCH_ITEM_DESCRIPTION_SUCCESS,
//       FETCH_ITEM_DESCRIPTION_FAILED,
//     ],
//     runOnce: `${FETCH_ITEM_DESCRIPTION}_${lng}_${eventId}`,
//     swagger: (api, authHeader) => {
//       return api["item-controller"].getDescriptionAndShortDescriptionUsingGET({
//         ...authHeader,
//         languageCode: lng,
//         eventId: parseInt(eventId, 10),
//         itemId,
//       });
//     },
//     itemId,
//     eventId,
//   };
// };

export const fetchItemDescription = (lng, eventId, itemId) => {
    return {
        type: FETCH_ITEM_DESCRIPTION,
        types: [
            FETCH_ITEM_DESCRIPTION_START,
            FETCH_ITEM_DESCRIPTION_SUCCESS,
            FETCH_ITEM_DESCRIPTION_FAILED,
        ],
        runOnce: `${FETCH_ITEM_DESCRIPTION}_${lng}_${eventId}`,
        axios: async (axiosInstance) => {
            return await axiosInstance.get(API_URLS.getDescriptionAndShortDescription, {
                params: {
                    languageCode: lng,
                    eventId: parseInt(eventId, 10),
                    itemId: itemId,
                },
            });
        },
        itemId,
        eventId,
    };
};

export const updateEvent = (event) => {
  return {
    type: UPDATE_EVENT,
    event: event,
  };
};

export const updateCurrentEventItem = (events) => {
  return {
    type: UPDATE_EVENT_DATA,
    events: events,
  };
};

export const activateCurrentEventItem = (itemId, eventId) => {
  return {
    type: ACTIVATE_CURRENT_EVENT_ITEM,
    eventId: eventId,
    itemId: itemId,
  };
};
