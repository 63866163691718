import loadable from "@loadable/component";
import Loader from '../../components/SharedComponents/Loader/Loader';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';

const LoadableUpcomingEventsView = loadable(() => import("../../components/UpcomingEventsView/UpcomingEventsView.js"), {
    fallback: <Loader/>
});

const UpcomingEventsView = () => {
    const {t} = useTranslation(['upcomingEvents', 'translation']);
    return (
        <>
            <Helmet>
                <title>{t('translation:metaTitlePage', {page: t('title')})}</title>
            </Helmet>
            <LoadableUpcomingEventsView/>
        </>
    );
}

export default UpcomingEventsView;
