import React, { useEffect } from "react";
import Routing from "./views/Routing";
import { useDispatch } from "react-redux";
import { windowResize, windowScroll } from "./store/actions/app";
import LanguageRouting from "./views/LanguageRouting";

import "./i18n";
import "./App.scss";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(windowResize());
    };
    const onScroll = () => {
      dispatch(windowScroll());
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", onScroll);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, [dispatch]);

  console.log(process.env.REACT_APP_ENV, "App Version:", 1.1);

  return (
    <LanguageRouting>
      <Routing />
    </LanguageRouting>
  );
}

export default App;
