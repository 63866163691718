import {
    USER_FETCH_LOGIN_URL,
    USER_FETCH_LOGIN_URL_SUCCESS,
    USER_FETCH_LOGIN_URL_FAILURE,
    USER_FETCH_TOKEN,
    USER_FETCH_TOKEN_SUCCESS,
    USER_FETCH_TOKEN_FAILURE,
    USER_LOG_OUT,
    USER_LOG_OUT_SUCCESS,
    USER_LOG_OUT_FAILURE,
    SWAGGER,
    USER_SUBSCRIBE_NEWSLETTER,
    USER_SUBSCRIBE_NEWSLETTER_SUCCESS,
    USER_SUBSCRIBE_NEWSLETTER_FAILURE,
    USER_DECLINE_NEWSLETTER,
    USER_DECLINE_NEWSLETTER_SUCCESS,
    USER_DECLINE_NEWSLETTER_FAILURE,
    USER_FETCH_AUCTIONS,
    USER_FETCH_AUCTIONS_SUCCESS,
    USER_FETCH_AUCTIONS_FAILURE, RESET_USER_ERROR_POPUP
} from './actionTypes';

import {ENV} from '../../config/app';
import Cookies from 'universal-cookie/lib';
import API_URLS from 'config/apiUrls';

// export const fetchUserLoginUrl = (language) => {
//     const cookie = new Cookies();
//     cookie.set('redirectAfterLogin', window.location.pathname, {path: '/'});
//     return {
//         type: SWAGGER,
//         types: [USER_FETCH_LOGIN_URL, USER_FETCH_LOGIN_URL_SUCCESS, USER_FETCH_LOGIN_URL_FAILURE],
//         swagger: (api, authHeader, action) => {
//             return api['login-controller'].getSessionIdUsingGET({...authHeader, languageCode: language, env: ENV});
//         }
//     }
// };

export const fetchUserLoginUrl = (language) => {
    const cookie = new Cookies();
    cookie.set('redirectAfterLogin', window.location.pathname, { path: '/' });

    return {
        types: [
            USER_FETCH_LOGIN_URL,
            USER_FETCH_LOGIN_URL_SUCCESS,
            USER_FETCH_LOGIN_URL_FAILURE,
        ],
        axios: async (axiosInstance) => {
            return await axiosInstance.get(API_URLS.getSessionId, {
                params: {
                    languageCode: language,
                    env: ENV, // Assuming ENV is defined somewhere
                },
            });
        },
    };
};

// export const fetchUserToken = () => {
//     return {
//         type: SWAGGER,
//         types: [USER_FETCH_TOKEN, USER_FETCH_TOKEN_SUCCESS, USER_FETCH_TOKEN_FAILURE],
//         swagger: (api, authHeader, action) => {
//             return api['login-controller'].fetchTokenUsingGET({...authHeader});
//         }
//     }
// };

export const fetchUserToken = () => {
    return {
        types: [
            USER_FETCH_TOKEN,
            USER_FETCH_TOKEN_SUCCESS,
            USER_FETCH_TOKEN_FAILURE,
        ],
        axios: async (axiosInstance) => {
            return await axiosInstance.get(API_URLS.fetchToken);
        },
    };
};

// export const userLogOut = () => {
//     return {
//         type: SWAGGER,
//         types: [USER_LOG_OUT, USER_LOG_OUT_SUCCESS, USER_LOG_OUT_FAILURE],
//         swagger: (api, authHeader, action) => {
//             return api['logout-controller'].logoutUsingGET({...authHeader});
//         }
//     }
// };

export const userLogOut = () => {
    return {
        types: [USER_LOG_OUT, USER_LOG_OUT_SUCCESS, USER_LOG_OUT_FAILURE],
        axios: async (axiosInstance) => {
            return await axiosInstance.get(API_URLS.logout);
        },
    };
};

// export const subscribeNewsletter = (email, language, confirmed) => {
//     return {
//         type: SWAGGER,
//         types: [USER_SUBSCRIBE_NEWSLETTER, USER_SUBSCRIBE_NEWSLETTER_SUCCESS, USER_SUBSCRIBE_NEWSLETTER_FAILURE],
//         swagger: (api, authHeader, action) => {
//             return api['newsletter-controller'].saveEmailUsingPOST({
//                     ...authHeader,
//                     email: email,
//                     languageCode: language,
//                     subscriptionConfirmed: !!confirmed
//                 });
//         },
//         email: email
//     }
// };

export const subscribeNewsletter = (email, language, confirmed) => {
    return {
        types: [
            USER_SUBSCRIBE_NEWSLETTER,
            USER_SUBSCRIBE_NEWSLETTER_SUCCESS,
            USER_SUBSCRIBE_NEWSLETTER_FAILURE,
        ],
        axios: async (axiosInstance) => {
            return await axiosInstance.post(API_URLS.saveEmail, {
                email: email,
                languageCode: language,
                subscriptionConfirmed: !!confirmed,
            });
        },
        email: email,
    };
};

// export const declineNewsletter = () => {
//     return {
//         type: SWAGGER,
//         types: [USER_DECLINE_NEWSLETTER, USER_DECLINE_NEWSLETTER_SUCCESS, USER_DECLINE_NEWSLETTER_FAILURE],
//         swagger: (api, authHeader, action) => {
//             return api['newsletter-controller'].saveEmailUsingPOST({...authHeader, subscriptionConfirmed: false});
//         }
//     }
// };

export const declineNewsletter = () => {
    return {
        types: [
            USER_DECLINE_NEWSLETTER,
            USER_DECLINE_NEWSLETTER_SUCCESS,
            USER_DECLINE_NEWSLETTER_FAILURE,
        ],
        axios: async (axiosInstance) => {
            return await axiosInstance.post(API_URLS.saveEmail, {
                subscriptionConfirmed: false,
            });
        },
    };
};

/*
    PARTICIPATED,
    WON,
    COMPLETED
*/
// export const fetchUserAuctions = (auctionType, lng) => {
//     return {
//         type: SWAGGER,
//         types: [USER_FETCH_AUCTIONS, USER_FETCH_AUCTIONS_SUCCESS, USER_FETCH_AUCTIONS_FAILURE],
//         swagger: (api, authHeader, action) => {
//             return api['buyer-controller'].getMyAuctionsUsingGET({...authHeader, type: auctionType, languageCode: lng});
//         },
//         auctionType: auctionType
//     }
// }

export const fetchUserAuctions = (auctionType, lng) => {
    return {
        types: [
            USER_FETCH_AUCTIONS,
            USER_FETCH_AUCTIONS_SUCCESS,
            USER_FETCH_AUCTIONS_FAILURE,
        ],
        axios: async (axiosInstance) => {
            return await axiosInstance.get(`${API_URLS.getMyAuctions}?type=${auctionType}&languageCode=${lng}`);
        },
        auctionType: auctionType,
    };
};

export const resetUserErrorPopup = () => {
    return {
        type: RESET_USER_ERROR_POPUP
    }
};
