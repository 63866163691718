import React from 'react';
import {useTranslation} from 'react-i18next';

import './PayBar.scss';

const PayBar = () => {
    const {t} = useTranslation();
    return (
        <div className="pay-bar">
            <a href={t('checkoutUrl')} target="_blank" rel="noopener noreferrer">
                {t('notificationBar.payItemsNow')}
                <span className="pay-bar-arrow">&rarr;</span>
            </a>
        </div>
    );
};

export default PayBar;
