export const UPCOMING = 'UPCOMING';
export const IN_PROGRESS = 'IN_PROGRESS';
export const ENDED = 'ENDED';
export const COMPLETED = 'COMPLETED';
export const PARTICIPATED = 'PARTICIPATED';
export const WON = 'WON';

export const HEADER_NORMAL = 'HEADER_NORMAL';
export const HEADER_INVERTED = 'HEADER_INVERTED';

export const STOMP_DESTINATION = '/exchange/liveAuctions.exchange';
export const STOMP_USER_DESTINATION = '/queue/liveAuctions.';

export const LIVESTREAM_PRE_WATCH_DIFFERENCE = 3600000;
export const LIVESTREAM_AFTER_WATCH_DIFFERENCE = 600000;
