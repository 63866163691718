let LNG;
let LOCALE;
let SWAGGER_ENDPOINT;
let OAUTH_ENDPOINT;
let OAUTH_REFRESH_ENDPOINT;
let WEBSOCKET_URL;
let WEBSOCKET_LOGIN;
let WEBSOCKET_PW;
let API_BASE_URL;;
let ENV;
let REACT_APP_ENV_BUILD_TIME;
let LOCALES = {
  de: "de-DE",
  en: "en-US",
  fr: "fr-FR",
};

const defaultLanguage = "de";
const browserLanguage = navigator.language.substring(0, 2);
const isVal = LOCALES[browserLanguage];

// To Do: Why local is hard coded?

if (process.env.REACT_APP_ENV === "live") {
  LNG = isVal ? browserLanguage : defaultLanguage;
  LOCALE = "de-DE";
  SWAGGER_ENDPOINT = "https://bidding-api-live.i-ways-network.org/v2/api-docs";
  API_BASE_URL = "https://bidding-api-live.i-ways-network.org";
  OAUTH_ENDPOINT =
      "https://bidding-api-live.i-ways-network.org/login/oauth/token";
  OAUTH_REFRESH_ENDPOINT =
      "https://bidding-api-live.i-ways-network.org/login/oauth/token/refresh";
  WEBSOCKET_URL = "wss://websocket-live.i-ways-network.org/ws";
  ENV = "LIVE";
} else if (process.env.REACT_APP_ENV === "development") {
  LNG = defaultLanguage;
  LOCALE = "de-DE";
  SWAGGER_ENDPOINT =
      "https://bidding-dev.liveauctions-de.i-ways-network.org/v2/api-docs";
  API_BASE_URL = 'https://bidding-dev.liveauctions-de.i-ways-network.org';
  OAUTH_ENDPOINT =
      "https://bidding-dev.liveauctions-de.i-ways-network.org/login/oauth/token";
  OAUTH_REFRESH_ENDPOINT =
      "https://bidding-dev.liveauctions-de.i-ways-network.org/login/oauth/token/refresh";
  WEBSOCKET_URL = "wss://ws-dev.liveauctions-de.i-ways-network.org/ws";
  ENV = "DEV";
} else {
  LNG = isVal ? browserLanguage : defaultLanguage;
  LOCALE = "de-DE";
  SWAGGER_ENDPOINT = "https://bidding-service.liveauctions-staging.i-ways-network.org/v2/api-docs";
  API_BASE_URL =
      'https://bidding-service.liveauctions-staging.i-ways-network.org';
  OAUTH_ENDPOINT =
      "https://bidding-service.liveauctions-staging.i-ways-network.org/login/oauth/token";
  OAUTH_REFRESH_ENDPOINT =
      "https://bidding-service.liveauctions-staging.i-ways-network.org/login/oauth/token/refresh";
  WEBSOCKET_URL = "wss://rabbit-mq.liveauctions-staging.i-ways-network.org/ws";
  ENV = "STAGING";
}

if (process.env.REACT_APP_ENV_BUILD_TIME) {
  REACT_APP_ENV_BUILD_TIME = process.env.REACT_APP_ENV_BUILD_TIME;
} else {
  REACT_APP_ENV_BUILD_TIME = new Date().getTime();
}

export {
    LNG,
    SWAGGER_ENDPOINT,
    OAUTH_ENDPOINT,
    OAUTH_REFRESH_ENDPOINT,
    ENV,
    WEBSOCKET_URL,
    LOCALE,
    WEBSOCKET_LOGIN,
    WEBSOCKET_PW,
    REACT_APP_ENV_BUILD_TIME,
    LOCALES,
    API_BASE_URL,
};
