import React from 'react';

import './Button.scss';

const Button = (props) => {
    const {
        disabled, onClick, type, className, children
    } = props;
    return (
        <div className={`button-wrapper ${className || ''}`}>
            <button
                type={type || 'button'}
                disabled={disabled}
                className="button"
                onClick={onClick}
            >
                {children}
            </button>
        </div>
    );
};

export default Button;
