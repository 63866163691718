export const findByIdentifier = (array, object, identifier) => array.find(
    (item) => item[identifier] === object[identifier]
);

export const findIndexByIdentifier = (array, object, identifier) => array.findIndex(
    (item) => item[identifier] === object[identifier]
);

export const updateObjectByIdentifier = (array, object, identifier) => {
    const index = findIndexByIdentifier(array, object, identifier);
    if (index !== -1) {
        array[index] = {
            ...array[index],
            ...object
        }
    }
    return array;
};
